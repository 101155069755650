
<template>  <div>
  
    <v-sheet tile class="d-flex">
      <v-btn icon class="ma-2" @click="prev">
        <v-icon>mdi-chevron-left</v-icon>
      </v-btn>
      <v-select
        v-model="type"
       
        :items="types"
        dense
        outlined
        hide-details
        class="ma-2"
        :item-text="'name'"
        :item-value="'code'"
        label="type"
      ></v-select>
      <v-select
        :items="years"
        dense
        outlined
        v-model="filter.annee"
        :clearable="true"
        hide-details
        class="ma-2"
        label="année"
          :key="filter.annee"
      
      ></v-select>
      <v-select
        :items="month"
        v-if="type == 'month' || type == 'list'"
        dense
        :clearable="true"
        outlined
        hide-details
        v-model="filter.mois"
          :key="filter.mois"
             :reduce="op => op.code"
        class="ma-2"
        :item-text="'name'"
        :item-value="'code'"
        label="mois"
      ></v-select>
      <v-text-field
        v-if="type == 'week' || type == 'list'"
        dense
        outlined
        :clearable="true"
        class="ma-2"
        v-model="filter.semaine"
        type="number"
        label="semaine"
      ></v-text-field>

      <v-btn icon class="ma-2" @click="next">
        <v-icon>mdi-chevron-right</v-icon>
      </v-btn>
    </v-sheet>

<myWeeklyPlanningClient   :filter="filter"  v-if=" type=='week'"/> 
<myMonthlyPlanningClient  :filter="filter"  v-if=" type=='month'"/>
<myYearlyPlanningClient   :filter="filter"  v-if=" type=='year'"/>
 <myListPlanning :filter="filter"  v-if="type == 'list'" /></div>
</template>


<script>

import myWeeklyPlanningClient from '../components/myWeeklyPlanningClient'
import myMonthlyPlanningClient from '../components/myMonthlyPlanningClient'
import myYearlyPlanningClient from '../components/myYearlyPlanningClient'
import myListPlanning from "../components/myListPlanning";
  export default {
  
     components: {
       myWeeklyPlanningClient,
       myMonthlyPlanningClient,
       myYearlyPlanningClient,
       myListPlanning
  },
 methods: {
    prev(){
      if (this.type == 'week'|| this.type == 'list')
      {
        if(  this.filter.semaine =="1")
        {
            this.filter.annee = (this.filter.annee-1).toString();
            this.filter.semaine = "52";
        }
        else
        {
           this.filter.semaine = (this.filter.semaine -1).toString();
        }
      }
       if (this.type == 'month')
      {
        if(  this.filter.mois =="01")
        {
            this.filter.annee = (this.filter.annee-1).toString();
            this.filter.mois ="12";
        }
        else{
             this.filter.mois =  ("0"+(this.filter.mois-1)).slice(-2)
        }
      }
       if (this.type == 'year' )
      {
        this.filter.annee = (this.filter.annee-1).toString();
      }
      /* if (this.type == 'list')
      {}*/
    },
    next(){
      if (this.type == 'week'|| this.type == 'list')
      {
        if(  this.filter.semaine =="52")
        {
            this.filter.annee = (parseInt(this.filter.annee)+1).toString();
            this.filter.semaine = "1";
        }
        else
        {
           this.filter.semaine = (parseInt(this.filter.semaine) +1).toString();
        }
      }
       if (this.type == 'month')
      {
        if(  this.filter.mois =="12")
        {
            this.filter.annee = (parseInt(this.filter.annee)+1).toString();
            this.filter.mois ="01";
        }
        else{

             this.filter.mois =  ("0"+(parseInt(this.filter.mois)+1)).slice(-2)
        }
      }
       if (this.type == 'year' )
      {
        this.filter.annee = (parseInt(this.filter.annee)+1).toString();
      }
      /* if (this.type == 'list')
      {}*/
    },
    switchCol(item) {
      item.colapsed = !item.colapsed;
    },
  },
  
    mounted() { 
      this.$store.commit('REFRESH_TASK')
      this.$nextTick(() =>{ 
        var ladate = new Date();
        var startDate = new Date(ladate.getFullYear(), 0, 1);
    var days = Math.floor((ladate - startDate) /
        (24 * 60 * 60 * 1000));
         
    var weekNumber = Math.ceil(days / 7);
         if (this.filter && !this.filter.annee)
          this.filter.annee=ladate.getFullYear();
        if (this.filter && !this.filter.mois)
          this.filter.mois= ("0"+(ladate.getMonth()+1)).slice(-2)
             if (this.filter && !this.filter.semaine)
          this.filter.semaine= weekNumber;
  });
    },
    data () {
      return {
       filter:{ 
       annee:new Date().getFullYear().toString(),
      mois:"",
      semaine:""},
        exampleRules: [ 
   
    v => ( v && v >= 52 ) || "le numero de semaine ne peux pas etre > 52",
    v => ( v && v <= 1 ) || "le numero de semaine ne peux pas etre < 1",
],

          type: 'month',
       types: [
           { name: "Annee", code: "year" }, 
           { name: "Mois", code: "month" },
            { name: "Semaine", code: "week" },
           { name: "Liste", code: "list" }],
      years: ["2028","2027","2026","2025","2024","2023", "2022"],
      month: [
        { name: "Janvier", code: "01" },
        { name: "Fevrier", code: "02" },
        { name: "Mars", code: "03" },
        { name: "Avril", code: "04" },
        { name: "Mai", code: "05" },
        { name: "Juin", code: "06" },
        { name: "Juillet", code: "07" },
        { name: "Aout", code: "08" },
        { name: "Septembre", code: "09" },
        { name: "Octobre", code: "10" },
        { name: "Novembre", code: "11" },
        { name: "Décembre", code: "12" }
      ],
           

    
      }
    },
  }
</script>
