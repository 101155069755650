var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.collabs,"item-key":"name","locale":"fr-FR","show-group-by":""},scopedSlots:_vm._u([{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{staticClass:"mr-2",attrs:{"small":""},on:{"click":function($event){return _vm.editItem(item)}}},[_vm._v(" mdi-pencil ")]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"mr-2",attrs:{"small":""},on:{"click":function($event){return _vm.setOfo(item)}}},'v-icon',attrs,false),on),[_vm._v(" mdi-calendar ")])]}}],null,true)},[_c('span',[_vm._v("Hors entreprise")])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"mr-2",attrs:{"small":""},on:{"click":function($event){return _vm.setTravel(item)}}},'v-icon',attrs,false),on),[_vm._v(" mdi-airplane ")])]}}],null,true)},[_c('span',[_vm._v("Deplacement")])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"mr-2",attrs:{"small":""},on:{"click":function($event){return _vm.setHomeoffice(item)}}},'v-icon',attrs,false),on),[_vm._v(" mdi-home ")])]}}],null,true)},[_c('span',[_vm._v("Teletravail")])])]}},{key:"top",fn:function(){return [_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-btn',{staticClass:"mx-2 float-right",attrs:{"fab":"","dark":"","color":"red"}},[_c('v-icon',{on:{"click":_vm.addone}},[_vm._v(" mdi-plus ")])],1)],1)],1)]},proxy:true},{key:"no-data",fn:function(){return [_vm._v(" pas de collaborateurs ")]},proxy:true}],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }