<template>
    <editCollabOfo :id='id'></editCollabOfo>
    </template>
    <script>
    import editCollabOfo from '../components/editCollabOfo'
    export default {
        mounted(){
         this.id=this.$route.params.id;
         this.$store.commit( 'UPDATE_SUBTITLE', "Indisponibilités");
        },
      name: "edit-collab-ofo-view",
        props: {
       
      },

   
        data(){
       return{
         id: null
        };},
      components: {
        editCollabOfo,
      }
    };
    </script>