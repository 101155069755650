import myApi from './myApi.js'
import myApiExcel from './myApiExcel.js'
export default {


  postSynthese(filter) {
    return   myApi.post('Synthese',filter)
  },
  downloadSynthese(filter) {

    myApiExcel.post('SyntheseExcel',filter).then((res) => {
       var FILE = window.URL.createObjectURL(new Blob([res.data], {type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,"}));
      window.open(FILE);
  });
   
  },
  postProd(filter) {
    return   myApi.post('Prod',filter)
  },
  downloadProd(filter) {

    myApiExcel.post('ProdExcel',filter).then((res) => {
       var FILE = window.URL.createObjectURL(new Blob([res.data], {type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,"}));
      window.open(FILE);
  });
},
  postRetard(filter) {
    return   myApi.post('Retard',filter)
  },
  downloadRetard(filter) {

    myApiExcel.post('RetardExcel',filter).then((res) => {
       var FILE = window.URL.createObjectURL(new Blob([res.data], {type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,"}));
      window.open(FILE);
  });},

  postNonatt(filter) {
    return   myApi.post('NonAtt',filter)
  }, 
   downloadNonatt(filter) {

    myApiExcel.post('NonAttExcel',filter).then((res) => {
       var FILE = window.URL.createObjectURL(new Blob([res.data], {type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,"}));
      window.open(FILE);
  });},

  postNonreal(filter) {
    return   myApi.post('Nonreal',filter)
  }, 
  
  downloadNonreal(filter) {

    myApiExcel.post('NonrealExcel',filter).then((res) => {
       var FILE = window.URL.createObjectURL(new Blob([res.data], {type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,"}));
      window.open(FILE);
  });},
  
 

}