import myApi from './myApi.js'
import myApiExcel from './myApiExcel.js'
export default {
  get() {
    return   myApi.get('Taches/Search')
  },
  download() {
    
    myApiExcel.get('Taches/Export').then((res) => {
      var FILE = window.URL.createObjectURL(new Blob([res.data], {type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,"}));
     window.open(FILE);
  })},
  todo(who) {
    return   myApi.post('Taches/Todo',{Who:who})
  },
  post(aTask) {
    return   myApi.post('Taches',aTask)
  },

  validate(aTask) {
    return   myApi.post('ValidationTaches',aTask)
  },
  delete(id) {
    return   myApi.delete('Taches/'+id)
  },
  getById(id) {
    return   myApi.get('Taches/'+id)
  },


}