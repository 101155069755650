import Vue from 'vue'
import VueRouter from 'vue-router'
import ProfileView from '../views/ProfileView.vue';
import ReportView from '../views/ReportView.vue';
import PlanningClientView from '../views/PlanningClientView.vue';
import PlanningCollabView from '../views/PlanningCollabView.vue';
import AdminView from '../views/AdminView.vue';
import monPlanningView from '../views/monPlanningView.vue';
import monPlanningSmallView from '../views/monPlanningSmallView.vue';
import CollabView from '../views/CollabView.vue';
import CollabOfoView from '../views/CollabOfoView.vue';
import CollabTravelView from '../views/CollabTravelView.vue';
import CollabHomeofficeView from '../views/CollabHomeofficeView.vue';
import ClientView from '../views/ClientView.vue';
import AlerteView from '../views/AlerteView.vue';
import DayoffView from '../views/DayoffView.vue';
import CalendarView from '../views/CalendarView.vue';
import { authenticationGuard } from '../auth';
Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: monPlanningView,
    beforeEnter: authenticationGuard,
  },
  
  {
    path: '/monPlanning',
    name: 'monPlanning',
    component: monPlanningView,
    beforeEnter: authenticationGuard,
  },
  {
    path: '/calendrier',
    name: 'calendrier',
    component: CalendarView,
    beforeEnter: authenticationGuard,
  },
  {
    path: '/monPlanningSmall',
    name: 'monPlanningSmall',
    component: monPlanningSmallView,
    beforeEnter: authenticationGuard,
  },
  {
    path: '/planningClient',
    name: 'planningClient',
    component: PlanningClientView,
    beforeEnter: authenticationGuard,
  },
  {
    path: '/planningCollab',
    name: 'planningCollab',
    component: PlanningCollabView,
    beforeEnter: authenticationGuard,
  },
  
  {
    path: '/report',
    name: 'report',
    component: ReportView,
    beforeEnter: authenticationGuard,
  },
  {
    path: '/profile',
    name: 'profile',
    component: ProfileView,
    beforeEnter: authenticationGuard,
  },
  {
    path: '/admin',
    name: 'admin',
    component: AdminView,
    beforeEnter: authenticationGuard,
  },
  {
    path: '/collabs/:id',
    name: 'collabs',
    component: CollabView,
    beforeEnter: authenticationGuard,
  },
  {
    path: '/collabOutOffice/:id',
    name: 'collabOutOffice',
    component: CollabOfoView,
    beforeEnter: authenticationGuard,
  },
  {
    path: '/collabTravel/:id',
    name: 'collabTravel',
    component: CollabTravelView,
    beforeEnter: authenticationGuard,
  } , {
    path: '/collabHomeoffice/:id',
    name: 'collabHomeoffice',
    component: CollabHomeofficeView,
    beforeEnter: authenticationGuard,
  },
  
  {
    path: '/collabs',
    name: 'addCollabs',
    component: CollabView,
    beforeEnter: authenticationGuard,
  },
  {
    path: '/clients/:id',
    name: 'clients',
    component: ClientView,
    beforeEnter: authenticationGuard,
  },
  {
    path: '/clients',
    name: 'addClients',
    component: ClientView,
    beforeEnter: authenticationGuard,
  },
  {
    path: '/dayoff/:id',
    name: 'dayoff',
    component: DayoffView,
    beforeEnter: authenticationGuard,
  },
  {
    path: '/dayoff',
    name: 'addDayoff',
    component: DayoffView,
    beforeEnter: authenticationGuard,
  },
  {
    path: '/alerte/:id',
    name: 'alerte',
    component: AlerteView,
    beforeEnter: authenticationGuard,
  },
  {
    path: '/alerte',
    name: 'addAlerte',
    component: AlerteView,
    beforeEnter: authenticationGuard,
  },
]

const router = new VueRouter({

  base: process.env.BASE_URL,
  routes
})

export default router
