<template>
  <v-dialog v-model="dialog" fullscreen hide-overlay transition="dialog-bottom-transition" scrollable>
    <v-card>
      <v-card-title class="pa-0" dark>
        <v-app-bar style="height: 50px;" dark color="primary">
          <v-btn icon dark @click="dialog = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>Tache: {{crtTask.name}}</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-toolbar-items>
            <v-btn dark text @click="save">
              Enregistrer
            </v-btn>
            <v-btn v-if="coulddel" dark text @click="supp">
              Supprimer
            </v-btn>
          </v-toolbar-items>
        </v-app-bar>
      </v-card-title>

      <v-card-text>

        <v-row>
         
          <v-col cols="12" sm="6">

<v-text-field  
v-model="crtTask.start" label="Start"></v-text-field>
</v-col>
<v-col cols="12" sm="6">

<v-text-field  
v-model="crtTask.end" label="End"></v-text-field>
</v-col>


        </v-row>
      </v-card-text>
      <div style="flex: 1 1 auto;"></div>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapState } from "vuex";
import { v4 as uuidv4 } from 'uuid';
export default {
  mounted() {


  },
  props: {
  }, computed: {
    ...mapState({
      alerts: "alerts",
      clients: "clients",
      collabs: "collabs",
      tasks: "tasks",
      user: "user",
      typeabs:"typeabs",
      recurrence: "recurrence",
      status: "status"

    }),
    
  },
  watch: {
    dialog(val) {

      if (!val)
        this.$emit("close")
    }
  },
  methods: {
    supp() {
   
      this.$emit("delete",this.crtTask);
     
      this.dialog = false;
    },
    save() {

      this.$emit("save",this.crtTask);
      this.dialog = false;
    },
    open(tache) {
      this.dialog = true;
      this.crtTask = tache
      if (this.crtTask.id) {
        this.coulddel =true;
      
      }else{  this.crtTask.id= uuidv4();
      }
      
    }
  },
  data() {
    return {
      coulddel:false,
      dialog: false,
      crtTask: {},
      menu1: false,
      menu2: false,
    }
  },
}
</script>