<template>
<div>      <v-tabs
          v-model="tab"
          align-with-title
            next-icon="mdi-arrow-right-bold-box-outline"
      prev-icon="mdi-arrow-left-bold-box-outline"
      show-arrows
        >
          <v-tabs-slider color="yellow"></v-tabs-slider>

          <v-tab
                   key="Collabs"
          >
         Collaborateurs
          </v-tab>
            <v-tab  key="Clients"
          >
         Clients
          </v-tab>
          <v-tab key="Taches"
          >
       Taches
          </v-tab>   
            <v-tab key="Calendrier"
          >
       Calendrier
          </v-tab>
                      <v-tab key="Alertes"
          >
       Alertes
          </v-tab>         
        </v-tabs>

        
          <v-tabs-items v-model="tab">
              <v-tab-item
              key="Collabs"
      >
      <adminCollab/>
      </v-tab-item>
        <v-tab-item
          key="Clients"
      >
 <adminClient/>
      </v-tab-item>
      <v-tab-item
          key="Taches"
      >
   <myListPlanning/>
      </v-tab-item>
      <v-tab-item
          key="Calendrier"
      >
   <adminCalendrier/>
      </v-tab-item>
           <v-tab-item
          key="Alertes"
      >
   <adminAlerte/>
      </v-tab-item>

    </v-tabs-items></div>
</template>
<script>
import adminAlerte from '../components/adminAlerte'
import adminCollab from '../components/adminCollab'
import adminClient from '../components/adminClient'
import myListPlanning from '../components/myListPlanning'
import adminCalendrier from '../components/adminCalendrier'
export default {
  name: "admin-view",
    props: {
   
  }, mounted() {
     this.$store.commit( 'UPDATE_SUBTITLE', "Administration")
        
  },
  components: {
 adminCollab,
 adminClient,
  adminAlerte,
 adminCalendrier,
 myListPlanning
  
  }, data () {
      return {
        tab: null,
        }
    },
};

 
</script>