var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-sheet',{staticClass:"d-flex",attrs:{"tile":"","height":"54"}},[_c('v-btn',{staticClass:"ma-2",attrs:{"icon":""},on:{"click":function($event){return _vm.$refs.calendar.prev()}}},[_c('v-icon',[_vm._v("mdi-chevron-left")])],1),_c('v-select',{staticClass:"ma-2",attrs:{"items":_vm.types,"dense":"","outlined":"","hide-details":"","label":"type","item-text":'value',"item-value":'id'},model:{value:(_vm.type),callback:function ($$v) {_vm.type=$$v},expression:"type"}}),(_vm.$refs.calendar)?_c('div',[_vm._v(" "+_vm._s(_vm.$refs.calendar.title)+" ")]):_vm._e(),_c('v-spacer'),_c('v-btn',{staticClass:"ma-2",attrs:{"icon":""},on:{"click":function($event){return _vm.$refs.calendar.next()}}},[_c('v-icon',[_vm._v("mdi-chevron-right")])],1)],1),_c('v-sheet',[_c('BR'),_c('v-row',[_c('v-col',{attrs:{"cols":"6"}},[_c('v-select',{attrs:{"items":_vm.collabs,"label":"Collaborateur","dense":"","item-value":'id',"multiple":"","item-text":'label',"chips":"","deletable-chips":"","clearable":""},scopedSlots:_vm._u([{key:"selection",fn:function(data){return [_c('v-chip',{attrs:{"text-color":"white","color":data.item.color,"small":""}},[_vm._v(_vm._s(data.item.name)+" "+_vm._s(data.item.prenom))])]}},{key:"item",fn:function(ref){
var item = ref.item;
var attrs = ref.attrs;
var on = ref.on;
return [_c('v-list-item',_vm._g(_vm._b({scopedSlots:_vm._u([{key:"default",fn:function(ref){
var active = ref.active;
return [_c('v-list-item-action',[_c('v-checkbox',{attrs:{"input-value":active}})],1),_c('v-list-item-content',[_c('v-list-item-title',[_c('v-row',{attrs:{"no-gutters":"","align":"center"}},[_c('v-chip',{attrs:{"text-color":"white","color":item.color,"small":""}},[_vm._v(_vm._s(item.name)+" "+_vm._s(item.prenom))])],1)],1)],1)]}}],null,true)},'v-list-item',attrs,false),on))]}},{key:"prepend-item",fn:function(){return [_c('v-list-item',{attrs:{"title":"Tous"},on:{"click":_vm.toggleCollab}},[_vm._v(" Tous ")]),_c('v-divider',{staticClass:"mt-2"})]},proxy:true}]),model:{value:(_vm.filter.collabs),callback:function ($$v) {_vm.$set(_vm.filter, "collabs", $$v)},expression:"filter.collabs"}})],1),_c('v-col',{attrs:{"cols":"6"}},[_c('v-select',{attrs:{"items":_vm.clients,"label":"Clients","dense":"","chips":"","deletable-chips":"","item-value":'id',"multiple":"","item-text":'name',"clearable":""},scopedSlots:_vm._u([{key:"prepend-item",fn:function(){return [_c('v-list-item',{attrs:{"title":"Tous"},on:{"click":_vm.toggleAllClient}},[_vm._v(" Tous ")]),_c('v-divider',{staticClass:"mt-2"})]},proxy:true}]),model:{value:(_vm.filter.clients),callback:function ($$v) {_vm.$set(_vm.filter, "clients", $$v)},expression:"filter.clients"}})],1)],1)],1),_c('v-sheet',{attrs:{"height":"700"}},[_c('v-calendar',{ref:"calendar",attrs:{"weekdays":_vm.weekday,"type":_vm.type,"events":_vm.filterEvent,"event-overlap-mode":_vm.mode,"event-overlap-threshold":30,"event-color":_vm.getEventColor},on:{"click:event":_vm.showEvent,"click:more":_vm.showEvents,"click:date":_vm.showEvents,"change":_vm.getEvents},scopedSlots:_vm._u([{key:"event",fn:function(ref){
var event = ref.event;
return [(event.statut!='07')?_c('p',[_vm._v(_vm._s(_vm.getLabel(event)))]):_c('p',{staticClass:"text-decoration-line-through"},[_vm._v(_vm._s(_vm.getLabel(event))+" ")])]}}]),model:{value:(_vm.value),callback:function ($$v) {_vm.value=$$v},expression:"value"}}),_c('manyTask',{ref:"editTasks",on:{"close":_vm.refresh}}),_c('oneTask',{ref:"editTask",on:{"close":_vm.refresh}}),_c('oneOfO',{ref:"editOfO",on:{"save":_vm.saveOfo,"delete":_vm.deleteOfo,"close":_vm.refresh}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }