<template>
  <div>      <v-tabs
            v-model="tab"
            align-with-title
              next-icon="mdi-arrow-right-bold-box-outline"
        prev-icon="mdi-arrow-left-bold-box-outline"
        show-arrows
          >
            <v-tabs-slider color="yellow"></v-tabs-slider>
  
            <v-tab
                     key="synth"
            >
           Synthéses
            </v-tab>
              <v-tab  key="prod"
            >
           Productivité
            </v-tab>
              <v-tab key="nonreal"
            >
         Non Réalisées
            </v-tab>
                        <v-tab key="nonatt"
            >
         Non attribuées
            </v-tab>         
            <v-tab key="retard"
            >
         Retard
            </v-tab>    
          </v-tabs>
  
          
            <v-tabs-items v-model="tab">
                <v-tab-item
                key="synth"
        >
        <rapportSyntheses/>
        </v-tab-item>
          <v-tab-item
            key="prod"
        >
   <rapportProd/>
        </v-tab-item>
        <v-tab-item
            key="nonreal"
        >
     <rapportNonreal/>
        </v-tab-item>
             <v-tab-item
            key="nonatt"
        >
     <rapportNonatt/>
        </v-tab-item>

        <v-tab-item
        key="retard"
    >
 <rapportRetard/>
    </v-tab-item>
      </v-tabs-items></div>
  </template>
  

<script>


import rapportSyntheses from '../components/rapportSyntheses'
import rapportRetard from '../components/rapportRetard'
import rapportProd from '../components/rapportProd'
import rapportNonreal from '../components/rapportNonreal'
import rapportNonatt from '../components/rapportNonatt'


export default {
  name: "planning-view",
  mounted() {
     this.$store.commit( 'UPDATE_SUBTITLE', "Rapports")
        
  },
  components: {
    rapportNonatt,
    rapportSyntheses,
    rapportRetard,
    rapportProd,
    rapportNonreal
  },
  methods: {

  }, data () {
      return {
        tab: null,
        }
    },
};
</script>