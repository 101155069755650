<template>
   <v-dialog
        v-model="dialog"
        fullscreen
        hide-overlay
        transition="dialog-bottom-transition"
        scrollable

      ><v-carousel v-model="model"   height="100%" style="height: 100%;width: 100%;"
    >
    <v-carousel-item   style="height: 100%;"
      v-for="crtTask in crtTasks"
      :key="crtTask.id"
    >
    <v-card style="height: 100%;">
           <v-card-title    class="pa-0" dark>                           <v-app-bar
            style="height: 50px;"
            dark
            color="primary"
          >
            <v-btn
              icon
              dark
              @click="dialog = false"
            >
              <v-icon>mdi-close</v-icon>
            </v-btn>
            <v-toolbar-title>Tache: {{crtTask.name}} </v-toolbar-title>
            &nbsp;{{(model +1 )}}/{{nb}}
            <v-spacer></v-spacer>
            <v-toolbar-items>
              <v-btn
                dark
                text
                @click="save(crtTask)"
                v-if=" (user.isAdmin || user.id == crtTask.collab) "
              >
                Enregistrer
              </v-btn>
              <v-btn
                dark  v-if="user.isAdmin" 
                text
                @click="supp(crtTask)"
              >
                Supprimer
              </v-btn>
              <v-btn  v-if="user.isAdmin" 
                dark
                text
                @click="addOne()"
              >
                Ajouter
              </v-btn>
            </v-toolbar-items>
          </v-app-bar></v-card-title>   
         
          <v-card-text>

            <v-row>
              <v-col cols="12" sm="6">
    
                <v-text-field :readonly="!user.isAdmin && !oneOfMine" 
                v-model="crtTask.name" label="Nom"> <template v-slot:prepend>
                    <v-icon v-if="!user.isAdmin && !oneOfMine"> mdi-lock </v-icon>
                  </template></v-text-field>
              </v-col>
    
    
              <v-col cols="12" md="3">
                <v-menu
                      v-model="menu2"
                      :close-on-content-click="false"
                      :nudge-right="40"
                      transition="scale-transition"
                      offset-y
                      min-width="auto"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                        v-on:change="refreshCollab"
                        v-model="crtTask.duedate"
                          label="Date"
                          prepend-icon="mdi-calendar"
                          readonly
                          v-bind="attrs"
                          v-on="on"
                        ></v-text-field>
                      </template>
                      <v-date-picker
                      local="FR-fr"
                      :first-day-of-week="1"
                      show-current
                      v-if="user.isAdmin  || oneOfMine"
                      v-on:change="refreshCollab"
                      v-model="crtTask.duedate"
                        @input="menu2 = false"
                      ></v-date-picker> </v-menu
                  >
               
              </v-col>
              <v-col cols="6" sm="3">
                <v-select
                v-model="crtTask.prevu"
               class=" pt-12"
        :items="timePick"
        label="Temps Prevu"
        :readonly="!user.isAdmin && !oneOfMine"   
        dense
        outlined
        :item-text="'label'"
                :item-value="'time'"
                v-on:change="refreshCollab" 
                clearable
      ></v-select>
                 </v-col>
              <v-col cols="12" md="3">
    
                <v-select :readonly="!user.isAdmin " ref="selectcollab" v-model="crtTask.collab" :items="externalCollab" label="Collaborateur" dense 
                 :item-value="'id'" v-if="loaded" :item-text="'nom'"  :clearable="user.isAdmin"  >
                 <template slot="selection" slot-scope="data">
                  <!-- HTML that describe how select should render selected items -->
                  {{ data.item.name }} {{ data.item.prenom }}
                </template>
    
                  <template v-slot:item="{item, attrs, on }">
                    <v-list-item v-on="on" v-bind="attrs" #default="{ active }">
                      <v-list-item-action>
                      <v-checkbox :input-value="active"></v-checkbox>
                      </v-list-item-action>
                      <v-list-item-content>
                        <v-list-item-title>
                          <v-row no-gutters align="center">
                          <v-chip text-color="white" :color="item.color" small
                          >{{ item.name }} {{ item.prenom }}</v-chip> {{item.dispo}} / {{item.capacite}}
                          <v-icon v-if="item.isTravel">mdi-airplane</v-icon>
                          <v-icon v-if="item.isHome">mdi-home</v-icon>
                          <v-icon v-if="item.isOfo">mdi-account-off</v-icon>
                          <v-icon v-if="item.willBeOver" >mdi-clock-alert-outline</v-icon>
                          </v-row>
                        </v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                  </template>
                
                </v-select>
              </v-col>
              <v-col cols="6" sm="3">
                <v-checkbox
                :readonly="!user.isAdmin && !oneOfMine" 
                v-model="crtTask.isMultiClient"
                label="Multi client"
                               ></v-checkbox>    </v-col>  
              <v-col cols="12" md="3">
                <v-select v-if=" !crtTask.isMultiClient" id="updateSelectClient"  
                :readonly="!user.isAdmin && !oneOfMine"   
                 v-model="crtTask.client"
                  :items="clients" label="Client" dense  :item-text="'name'"
                  :item-value="'id'" :clearable="user.isAdmin || oneOfMine"></v-select>
                  
            
                
                  <v-select id="createSelectClient" v-if=" crtTask.isMultiClient" 
                  :readonly="!user.isAdmin && !oneOfMine"
                   multiple
                   v-on:change="handleChange"
          
                    v-model="crtTask.clients"
                     :items="clients"
                      label="Client" 
                      dense  :item-text="'name'"
                  :item-value="'id'" :clearable="user.isAdmin || oneOfMine">
                  
                  <template v-slot:prepend-item>
                    <v-list-item
                      title="Tous" @click="toggle(crtTask)"> Tous
                    </v-list-item>
            
                    <v-divider class="mt-2"></v-divider>
                  </template></v-select>
              </v-col>
    
    
              <v-col cols="12" sm="3">
    
                <v-select :readonly="!user.isAdmin && !oneOfMine" v-model="crtTask.recurrence" :items="recurrence" label="recurrence" dense  :item-text="'name'"
                  :item-value="'code'" :clearable="user.isAdmin || oneOfMine"></v-select>
         
              </v-col>
              <v-col cols="12" sm="6">
                <v-menu v-if="crtTask.recurrence && crtTask.recurrence!=0"
                      v-model="menu1"
                      :close-on-content-click="false"
                      :nudge-right="40"
                      transition="scale-transition"
                      offset-y
                      min-width="auto"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                        v-model="crtTask.finrecurrence"
                          label="Fin de recurrence"
                          prepend-icon="mdi-calendar"
                          readonly
                          v-bind="attrs"
                          v-on="on"
                        ></v-text-field>
                      </template>
                      <v-date-picker
                     v-if="user.isAdmin ||  oneOfMine"
                      v-model="crtTask.finrecurrence"
                      locale="FR-fr"
                      :first-day-of-week="1"
                        @input="menu = false"
                      ></v-date-picker> </v-menu
                  >
               </v-col>  <v-col cols="12" sm="6">
               <v-switch :readonly="!user.isAdmin && !oneOfMine"
               
               v-model="crtTask.strict" v-if="crtTask.recurrence && crtTask.recurrence!=0" label="Echeance stricte (jour ouvré predecent)" >
                 </v-switch></v-col>
              <v-col cols="12" sm="3">
               
                <v-select v-if="!user.isAdmin && !oneOfMine" outlined  v-model="crtTask.statut" :items="status" label="Statut" dense  :item-text="'name'"
                  :item-value="'code'"
                  :item-disabled="'adminOnly'" ></v-select>
                  
                  <v-select v-if="user.isAdmin ||  oneOfMine" outlined  v-model="crtTask.statut" :items="status" label="Statut" dense  :item-text="'name'"
                  :item-value="'code'"
                  ></v-select>
              </v-col>
              <v-col cols="12" sm="3">
                <v-select
                v-model="crtTask.realise"
        :items="timePick"
        label="Temps Consommé"
     
        dense
        outlined
        :item-text="'label'"
                :item-value="'time'"
          
                clearable
      ></v-select>
                    </v-col>
              <v-col cols="12" sm="3">
                <v-select
                v-model="crtTask.restante"
        :items="timePick"
        label="Temps Restant"
    
        dense
        outlined
        :item-text="'label'"
                :item-value="'time'"
          
                clearable
      ></v-select>
                  </v-col>
    
    
            </v-row>
            <v-row>
              <v-col cols="12" sm="3" v-if="crtTask.isMultiClient">
                 <v-list dense>
           
         
                  <v-subheader>Avancement</v-subheader>
                  <v-list-item
                    v-for="(item, i) in crtTask.clients"
                    :key="i"
                  >
               
                    <v-list-item-content>
                      <v-checkbox
                               
                      v-model="done[i]"
                   
                     
                      >
                      <template v-slot:label>
        <span   v-if="done[i]" class="text-decoration-line-through">{{ getClientName(item)}}</span>
        <span   v-else >{{ getClientName(item)}}</span>
      </template></v-checkbox>
                    </v-list-item-content>
                  </v-list-item>
              
              </v-list>      </v-col>
           
              <v-col cols="12" :sm="crtTask.isMultiClient?9:12">
                <v-textarea :readonly="!user.isAdmin && !oneOfMine" v-model="crtTask.text" label="Description" counter maxlength="240" full-width single-line>
                </v-textarea>
                <v-textarea v-model="crtTask.commentaire" label="Commentaire" counter maxlength="240" full-width single-line>
                </v-textarea>
              </v-col>
            </v-row>
          </v-card-text>
          <div style="flex: 1 1 auto;"></div>
        </v-card>
     </v-carousel-item>
     <oneTask   ref="editTask"/>
     <v-dialog
      v-model="confirm"
      width="500"
    >

      <v-card>
        <v-card-title class="text-h5 red dark">
          Attention !
        </v-card-title>

        <v-card-text>
          <br/>
          <v-alert
      prominent
      type="error"
      v-for="(err) in errors"
      :key="err"
    >
      <v-row align="center">
       {{err.message}}
      </v-row>
    </v-alert>
           </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            text
            @click=" forceSave(false,false);"
          >
           Forcer
          </v-btn>
          <v-btn
          color="primary"
          text
          @click=" forceSave(true,false);"
        >
         Decaler avant
        </v-btn>
        <v-btn
        color="primary"
        text
        @click=" forceSave(false,true);"
      >
      Decaler apres
      </v-btn>
          <v-btn
            color="primary"
            text
            @click="confirm = false"
          >
           Annuler
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-carousel>   
 </v-dialog></template>

      <script>
      import serviceCollabs from "@/service/serviceCollabs";
        import serviceTache from "@/service/serviceTache";
   import oneTask from "../components/oneTask.vue";    
import { mapState } from "vuex";
  export default {
    created() {
    this.refreshCollab();

  },
     props: {
  },
      watch: {
        'crtTask.isMultiClient': {
      handler: function () {
        this.clientsSave = []
        this.done =[]
        }
      },
      
    crtTask: {
      handler: function (val) {
   
        this.settodo(val)
      },
      deep: true
    },
      dialog(val) {
    
      if(!val)
        this.$emit("close")
    }},
     methods: {
      refreshCollab(){
      this.loaded = false;
       serviceCollabs.CollabsAvailable(this.crtTask.duedate,this.crtTask.prevu,"").then(response => {
       // this.internalCollab = [...response.data];
        this.setlaode();
        this.setColl( [...response.data]);
})
    },    setColl(data) {
      this.internalCollab=[...data]
      },
    setlaode() {
      this.loaded = true;
    },
      settodo(val){   
      let donelocal= this.done;
      if( val.isMultiClient  && donelocal.length>0)
        {    var j=0;
              var state=[]

            if(  this.clientsSave.length > val.clients.length )
            {
              for (var i = 0; i <     this.clientsSave.length; i++) {

                if( this.clientsSave[i] === val.clients[j])
                {

                  state.push(donelocal[i]);
                  j++
                }
              }
              donelocal.splice(0,    donelocal.length)
 
 state.forEach((item) => {
  donelocal.push(item)})
            }
            if( this.clientsSave.length < val.clients.length )
            {

              for (var t = 0;t <     val.clients.length; t++) {

                if( this.clientsSave[j] === val.clients[t])
                {
                  state.push(donelocal[j]);
                  j++
                }
                else
                {
            
                  state.push(false);
                }
              }
              donelocal.splice(0,   donelocal.length)
 
              state.forEach((item) => {
                donelocal.push(item)})
             // this.done = state;
            }

        }
        else{
          if( val.isMultiClient )
          {

            donelocal.push(false)
          }
        
        }
        if(val.clients)
        {
        this.clientsSave = val.clients
        }
        else{
          this.clientsSave = []
        }},
    handleChange(){
     this.settodo(this.crtTask);

     var tmpdes = this.crtTask.text;
     this.$nextTick(() =>{   this.crtTask.text="";
     this.$nextTick(() =>{   this.crtTask.text = tmpdes;})})

    },
    toggle (crtTask) {

    var toadd =  this.clients.map((c)=>{return c.id}).slice();
    if(crtTask.clients  && crtTask.clients.length>0 )
    { 
      crtTask.clients.splice(0,crtTask.clients.length)}
    else{
    toadd.forEach((item) => {
        crtTask.clients.push(item)
    })
    
  }
  this.settodo(crtTask); 
  var tmpdes = crtTask.text;
     this.$nextTick(() =>{   crtTask.text="";
     this.$nextTick(() =>{   crtTask.text = tmpdes;})})
      },
      
    getClientName(anId)
    {
      var trouves = this.clients.filter(c=>c.id == anId);
      if(trouves.length>0 )
      {
        return trouves[0].name
      }
      return "";
    },
      supp(crtTask){ 
      
      this.$store.commit('DELETE_TASK',crtTask.id)

  this.dialog=false;},
  addOne(){this.$refs.editTask.open( {});},

  forceSave(avant,apres) {
      this.confirm = false,
      this.crtTask.forceAvant=avant,
      this.crtTask.forceApres=apres,
      this.$store.commit('UPDATE_TASK', this.crtTask)
      this.$emit("close")
      this.dialog = false;
    },  

    save(crtTask) {


      if(crtTask.clients && crtTask.clients.length>0 && crtTask.isMultiClient)
      { 
      this.errors =[];
      crtTask.client=crtTask.clients[0]
      crtTask.clientsId=crtTask.clients;
      crtTask.doneByClient=this.done;
      serviceTache. validate( crtTask).then((err) => {  
        this.$emit("close")
       if(err.data && err.data.length>0)
       {
        this.confirm =true;
        this.errors =err.data;
        this.crtTask=crtTask;
       }
       else {
      this.$store.commit('UPDATE_TASK', crtTask)
      this.dialog = false;
    }
  })

      }
         else {

          crtTask.clientsId=crtTask.clients;
      this.  errors =[];
      serviceTache. validate( this.crtTask).then((err) => {  
     
       if(err.data && err.data.length>0)
       {
        this.confirm =true;
        this.errors =err.data;
        this.crtTask=crtTask;
       }
       else{
      this.$store.commit('UPDATE_TASK', this.crtTask)
      this.$emit("close")
      this.dialog = false;}})}
    },



    open(taches) {
      this.dialog=true;
      this.nb=taches.length;
      this.crt=1;
      this.crtTasks =taches
    }
  }, components: {oneTask},
  computed: {    
    
    oneOfMine(){return (this.crtTask.isImprevu && this.crtTask.collab === this.user.id)},
    externalCollab() {return this.internalCollab},
         ...mapState({
      alerts: "alerts",
      clients: "clients",
      collabs: "collabs",
      tasks: "tasks",
      user: "user",
      timePick:"timePick",
      recurrence: "recurrence",
      status: "status"
    }),},
    data () {
      return {
         model: 0,
         nb:0,
         cdrt:0,
        crtTasks:[],
        coulddel:false,
      dialog: false,
      loaded:false,
      errors:[],
      all:true,
      crtTask: {strict:false, clients:[]},
      menu1: false,
      menu2: false,
      confirm:false,
      internalCollab:[],
      done:[],
      clientsSave:[]
      }
    },
  }
</script>