import myApi from './myApi.js'

export default {
  get() {
    return   myApi.get('Clients')
  },
  post(aCollab) {
    return   myApi.post('Clients',aCollab)
  },
  delete(id) {
    return   myApi.delete('Clients/'+id)
  },
  getById(id) {
    return   myApi.get('Clients/'+id)
  },
 

}