<template>
  <v-sheet>
    <v-row>
      <v-col :cols="3">
        <v-list two-line flat>
          <template v-for="collab in filtrableCollab">
            <v-list-item :key="collab.email">
              <template>
                <v-list-item-action>
                  <v-checkbox v-model="collab.active"></v-checkbox>
                </v-list-item-action>
                <v-list-item-content>
                  <v-list-item-title>
                    <h1>
                      <v-chip style="width: 100%;" :color="collab.color" dark>{{ collab.prenom }} {{ collab.name }}</v-chip>
                    </h1>
                  </v-list-item-title>
                  <v-list-item-subtitle>{{ collab.email }}</v-list-item-subtitle>
                </v-list-item-content>
              </template>
            </v-list-item>
          </template>
        </v-list>
      </v-col>
      <v-col :cols="9">
        <Calendar :data-source="filteredTask" :year="filter.annee" :display-week-number=displayweeknumber
        @click-day="clickday"
                :enable-context-menu="true"
           :context-menu-items="contextMenuItems"
          :display-header=displayheader language="fr"
          class="pb-12"></Calendar>
      </v-col>
    </v-row>
    <oneTask   ref="editTask"/>
          <manyTask   ref="editTasks"/>
  </v-sheet>
</template>

<script>

import Calendar from 'v-year-calendar';
import { mapState } from "vuex";
import 'v-year-calendar/locales/v-year-calendar.fr';
import manyTask from "../components/manyTask.vue";
import oneTask from "../components/oneTask.vue";
export default {
  components: {
    Calendar,manyTask,oneTask
  }, 
  mounted() {
    this.filtrableCollab = this.initFiltrableCollab();
 
  },
  methods: {
    clickday( date ){
      if(date.events.map((event)=>event.task) && date.events.map((event)=>event.task).length >0 ){
          this.$refs.editTasks.open(  date.events.map((event)=>event.task));
      }
      else
      {
 
        this.$refs.editTask.open({duedate:date.date.getFullYear() +'-' +(date.date.getMonth()+1) +'-'+date.date.getDate()});
      }
   
    },
        daycontextmenu( date ){
          
      this.snackbartext=date.events;
      
    },
    initFiltrableCollab() {
      var res = this.collabs.map(x => ({
        ...x,
        active: true
      }))
      console.log(res);
      return res;
    },
    switchCol(item) {
      item.colapsed = !item.colapsed
    }
  }, props: {

    filter: {
      type: Object,
      default: () => ({
        annee: new Date().getFullYear().toString(),
        mois: "",
        semaine: ""
      }),
    },
  },
  computed: {
    ...mapState({
      tasks: "tasks",
      snackbartext:'snackbartext',
      collabs: "collabs",
      user:"user",
    }),

    filteredTask() {
      var res = this.tasks;
      if (this.filter && this.filter.annee)
        res = res.filter((tache) => tache.annee == this.filter.annee);
      res = res.filter((tache) => this.filtrableCollab.filter(collab => collab.active).map(collab => collab.id).includes(tache.collab));
      res = res.map(tache => {
        var colorcollab = this.collabs.filter(collab => collab.id == tache.collab)[0].color
        return {
          id: tache.id,
          name: tache.name,
          color: colorcollab,
          startDate: new Date(tache.annee, tache.mois - 1, tache.jour),
          endDate: new Date(tache.annee, tache.mois - 1, tache.jour),
          task:tache,
        };
      })
      return res;
    },
  },
  data() {
    return {
        contextMenuItems: [
        {
          text: "Editer",
          click: evt => {
             console.log(evt) 
           console.log('will edit')
          }
        },
        {
          text: "Supprimer",
          click: evt => {
            console.log(evt) 
               console.log('will delete')
           // this.dataSource = this.dataSource.filter(item => item.id != evt.id);
          }
        }
      ],
      displayweeknumber: false,
      displayheader: false,
      exampleRules: [

        v => (v && v >= 52) || "le numero de semaine ne peux pas etre > 52",
        v => (v && v <= 1) || "le numero de semaine ne peux pas etre < 1",
      ],

      filtrableCollab: null


    }
  }
}
</script>
