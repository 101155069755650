import axios from 'axios'
export default axios.create({
  //baseURL: 'https://mqutest01.azurewebsites.net'
  timeout: 5000,
  baseURL:'https://mazheprod.northeurope.cloudapp.azure.com:5000/',
  responseType: 'blob',
  headers: {
    'X-Auth-Token': 'f2b6637ddf355a476918940289c0be016a4fe99e3b69c83d',
    'Content-Type': 'application/json'
    
  }
})