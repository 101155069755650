<template>
    <editClient :id='id'></editClient>
    </template>
    <script>
    import editClient from '../components/editClient'
    export default {
        mounted(){
         this.id=this.$route.params.id;
         this.$store.commit( 'UPDATE_SUBTITLE', "Clients");
        },
      name: "edit-collab-view",
        props: {
       
      },
        data(){
       return{
         id: null
        };},
      components: {
        editClient,
      }
    };
    </script>