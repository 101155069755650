<template>
    <v-card >
      <v-card-title class="text-h5">Profile</v-card-title>
      <v-card-text>
     <v-row>    

       <v-col cols="6">    <v-text-field label="Nom" style="color:black!important" v-model="user.name"></v-text-field> </v-col> 
       <v-col cols="6">   <v-text-field label="Prenom" v-model="user.prenom"></v-text-field></v-col> 
        <v-col cols="6">  <v-text-field label="mail" readonly v-model="user.email" ></v-text-field></v-col> 
         </v-row>
          
      </v-card-text>
      <v-card-actions>
      
            <v-btn v-if="!$auth.isAuthenticated" text color="blue darken-1" @click="login">Log in</v-btn>
    
            <v-btn v-if="$auth.isAuthenticated" text color="blue darken-1"  @click="logout">Deconnexion</v-btn>
      
        <v-btn color="blue darken-1" text @click="closeProfile">Annuler</v-btn>
        <v-btn color="blue darken-1" text @click="validateProfile">Enregistrer</v-btn>
        <v-spacer></v-spacer>
      </v-card-actions>
    </v-card>
    

</template>


<script>

import router from "@/router";
import { mapState } from "vuex";
export default {
  name: "view-profile",
  mounted() {
     this.$store.commit( 'UPDATE_SUBTITLE', "profile")
        
  },
  data() {
    return {
       rules: [
        value => !value || value.size < 2000000 || 'Avatar size should be less than 2 MB!',
      ],
      drawer: false,
      avatar:null,
    };
  },
  components: {},
  computed: {

    ...mapState({
      user: "user",
      baseUrl:"baseUrl",
    }),
  },


  methods: {
   
   
    gotoHome() {
      router.push({ name: "home", params: { id: this.user.id } });
    },
 

    login() {
      this.$auth.loginWithRedirect();
    },
    logout() {
      this.$auth.logout({
        returnTo: window.location.origin,
      });
    },

    validateProfile() {
      this.$store.commit('UPDATE_COLLAB',this.user)
         this.$router.go(-1)
    },
    closeProfile() {
         this.$router.go(-1)
    },
  },
};
</script>
