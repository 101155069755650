<template>
    <editCollabTravel :id='id'></editCollabTravel>
    </template>
    <script>
    import editCollabTravel from '../components/editCollabTravel'
    export default {
        mounted(){
         this.id=this.$route.params.id;
         this.$store.commit( 'UPDATE_SUBTITLE', "Déplacement");
        },
      name: "edit-collab-travel-view",
        props: {
       
      },

   
        data(){
       return{
         id: null
        };},
      components: {
        editCollabTravel,
      }
    };
    </script>