<template>
  <div>
    <v-data-table
      :headers="headers"
      :items="filteredTask"
      item-key="id"
      class="elevation-1"
      locale="fr-FR"
      sort-by="duedate"
      @dblclick:row="editItem"
  show-group-by
  :search="search"
    >


    <template v-slot:[`item.actions`]="{ item }">
      <v-icon
        small
        class="mr-2"
        @click="editOneItem(item)"
      >
        mdi-pencil
      </v-icon>
      
    </template>
    <template v-slot:top>
        <v-row>
          <v-col cols="8">
            <v-text-field v-model="search" label="Rechercher " class="mx-4"></v-text-field>
          </v-col>
          <v-col cols="4">
            <v-btn
            class="mx-2 float-right"
            fab
            dark
            color="red" 
          >   <v-icon  @click="download">
            mdi-file-download-outline
                    </v-icon></v-btn>
            <v-btn  v-if="user.isAdmin"
      class="mx-2 float-right"
      fab
      dark
      color="red" 
    >   <v-icon @click="addone">
                mdi-plus
              </v-icon></v-btn>
          </v-col>

        </v-row>
      </template>
    </v-data-table>
     <oneTask   ref="editTask"/>
  </div>
</template>

<script>
import { mapState } from "vuex";
import oneTask from "../components/oneTask.vue";
import serviceTache from '../service/serviceTache.js'
  export default {
    data () {
      return {
        search: '',
  
      }
    },   props: {
    
    filter: {
      type: Object,
      default: () => ({annee:"",
      mois:"",
      semaine:""}),
    },
  },   components: {oneTask},
    computed: {
         ...mapState({
      tasks: "tasks",
      user:"user",
    }),
  
     filteredTask (){
      var res = this.tasks;
  
    if(this.filter && this.filter.annee)
        res = res.filter((tache)=> tache.annee == this.filter.annee);
    if(this.filter && this.filter.mois)
        res = res.filter((tache)=> tache.mois == this.filter.mois);
    if(this.filter && this.filter.semaine)
        res = res.filter((tache)=> tache.semaine == this.filter.semaine);
      return res;
     },
      headers () {
        return [
          { text: 'Date',value: 'duedate'},
          { text: 'Client', value: 'clientLabel' },
          { text: 'Collaborateur', value: 'collabLabel' },
          { text: 'Tache', value: 'name' },
          { text: 'Prevue (heures)', value: 'prevu',groupable:false },
          { text: 'Réalisé (heures)', value: 'realise',groupable:false },
          { text: 'Restant (heures)', value: 'restante',groupable:false },
          { text: 'Statut', value: 'statutLabel' },
          { text: 'Actions', value: 'actions', sortable: false , groupable:false},
        ]
      },
    
    },
    methods: {  
      mounted() {
     
        this.$store.commit('REFRESH_TASK')
      },
      download(){

this.$nextTick(() =>{     serviceTache.download();})
},
      addone(){this.$refs.editTask.open( {});},
      editOneItem(item){
     
        this.$refs.editTask.open(item);},
      editItem (event, {item}) {
       console.log(event)
        this.$refs.editTask.open( item);
    },
      filterOnlyCapsText (value, search, item) {
        console.log(item)
        return value != null &&
          search != null &&
          typeof value === 'string' &&
          value.toString().toLocaleUpperCase().indexOf(search) !== -1
      },
    },
  }
</script>