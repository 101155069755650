<template>
    <editDayoff :id='id'></editDayoff>
    </template>
    <script>
    import editDayoff from '../components/editDayoff'
    export default {
        mounted(){
         this.id=this.$route.params.id;
         this.$store.commit( 'UPDATE_SUBTITLE', "Fériés");
        },
      name: "edit-collab-view",
        props: {
       
      },
    
        data(){
       return{
         id: null
        };},
      components: {
        editDayoff,
      }
    };
    </script>