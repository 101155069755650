<template>
    <editAlerte :id='id'></editAlerte>
    </template>
    <script>
    import editAlerte from '../components/editAlerte'
    export default {
        mounted(){
         this.id=this.$route.params.id;
         this.$store.commit( 'UPDATE_SUBTITLE', "Alertes");
        },
      name: "edit-alerte-view",
        props: {
       
      },
        data(){
       return{
         id: null
        };},
      components: {
        editAlerte,
      }
    };
    </script>