import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import router from './router';
import store from './stores';
import { VueMaskDirective } from 'v-mask'
import VueApexCharts from 'vue-apexcharts'

// Import the Auth0 configuration
//import { domain, clientId } from '../auth_config.json';
// Import the plugin here
import { Auth0Plugin } from './auth';

import './registerServiceWorker'
var domain= "kermatgestion.eu.auth0.com";
var client_id="jkCgxlFrMfQhL9JwHTdbCVljIXnSl3uZ";
// Install the authentication plugin here
Vue.use(Auth0Plugin, {
  domain,
  client_id,
  onRedirectCallback: appState => {
    router.push(
      appState && appState.targetUrl
        ? appState.targetUrl
        : window.location.pathname
    );
  }
});
Vue.use(VueApexCharts)

Vue.component('apexChart', VueApexCharts)

Vue.directive('mask', VueMaskDirective);
Vue.config.productionTip = false;
Vue.config.unwrapInjectedRef = true

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app');

