import myApi from './myApi.js'
//https://calendrier.api.gouv.fr/jours-feries/metropole/2021.json
export default {
  get() {
    return   myApi.get('Dayoffs')
  },
week(year,week)
{
  return   myApi.get('week/'+year+'/'+week)
},
  post(aDayoff) {
    return   myApi.post('Dayoffs',aDayoff)
  },
  delete(id) {
    return   myApi.delete('Dayoffs/'+id)
  },
  getById(id) {
    return   myApi.get('Dayoffs/'+id)
  },
 

}