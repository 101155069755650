<template>
  <div>
    <v-data-table
      :headers="headers"
      :items="collabs"
      item-key="name"
      class="elevation-1"
      locale="fr-FR"
      show-group-by
    >
 
    <template v-slot:[`item.actions`]="{ item }">
      <v-icon
        small
        class="mr-2"
        @click="editItem(item)"
      >
        mdi-pencil
      </v-icon>

      <v-tooltip top>
        <template v-slot:activator="{ on, attrs }">
    

          <v-icon
          small
          class="mr-2"
          @click="setOfo(item)"
          v-bind="attrs"
          v-on="on"
        >
          mdi-calendar
        </v-icon>
        </template>
        <span>Hors entreprise</span>
      </v-tooltip>
      <v-tooltip top>
        <template v-slot:activator="{ on, attrs }">
    

          <v-icon
          small
          class="mr-2"
          @click="setTravel(item)"
          v-bind="attrs"
          v-on="on"
        >
        mdi-airplane
        </v-icon>
        </template>
        <span>Deplacement</span>
      </v-tooltip>
      <v-tooltip top>
        <template v-slot:activator="{ on, attrs }">
    

          <v-icon
          small
          class="mr-2"
          @click="setHomeoffice(item)"
          v-bind="attrs"
          v-on="on"
        >
        mdi-home
        </v-icon>
        </template>
        <span>Teletravail</span>
      </v-tooltip>
    </template>
    <template v-slot:top>
        <v-row>
          <v-col cols="12">
            <v-btn
      class="mx-2 float-right"
      fab
      dark
      color="red" 
    >   <v-icon @click="addone">
                mdi-plus
              </v-icon></v-btn>
          </v-col>

        </v-row>
      </template>
    
    <template v-slot:no-data>
     pas de collaborateurs
    </template>
    </v-data-table>
  </div>
</template>

<script>
import { mapState } from "vuex";
import router from "@/router";
  export default {
    data () {
      return {
     
      }
    }, 


    computed: {
         ...mapState({
      collabs: "collabs",


    }),
      headers () {
        return [
          { text: 'Nom',value: 'name',},
          { text: 'Prenom', value: 'prenom' },
          { text: 'Email', value: 'email' },
          { text: 'Capacite', value: 'capacite' },
          { text: 'Actions', value: 'actions', sortable: false , groupable:false},
        ]
      },
    },
    methods: {
      addone(){

        router.push({ name: "addCollabs" });
      },
      setOfo (item) {
      
      router.push({ name: "collabOutOffice", params: { id: item.id } });
    },
    setTravel (item) {
      
      router.push({ name: "collabTravel", params: { id: item.id } });
    },
    setHomeoffice (item) {
      
      router.push({ name: "collabHomeoffice", params: { id: item.id } });
    },
      editItem (item) {
      
        router.push({ name: "collabs", params: { id: item.id } });
      },

    },
  }
</script>