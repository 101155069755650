<template>
    <div>
      <v-sheet
        tile
        height="54"
        class="d-flex"
      >
        <v-btn
          icon
          class="ma-2"
          @click="$refs.calendar.prev()"
        >
          <v-icon>mdi-chevron-left</v-icon>
        </v-btn>
        <v-select
          v-model="type"
          :items="types"
          dense
          outlined
          hide-details
          class="ma-2"
          label="type"
          :item-text="'value'"
              :item-value="'id'"
        ></v-select>
     <div v-if="$refs.calendar">          {{ $refs.calendar.title }}
     </div>
       
        <v-spacer></v-spacer>
   
        <v-btn
          icon
          class="ma-2"
          @click="$refs.calendar.next()"
        >
          <v-icon>mdi-chevron-right</v-icon>
        </v-btn>
   
      </v-sheet>
     
      <v-sheet>
<BR/>        
        <v-row>
          <v-col cols="6">  <v-select 
       v-model="filter.collabs" :items="collabs" label="Collaborateur" dense 
      :item-value="'id'" multiple :item-text="'label'"  chips deletable-chips clearable  >

      <template slot="selection" slot-scope="data">
        <!-- HTML that describe how select should render selected items -->
       <v-chip text-color="white" :color="data.item.color" small
              >{{ data.item.name }} {{ data.item.prenom }}</v-chip> 
      </template>

      <template v-slot:item="{item, attrs, on }">
        <v-list-item v-on="on" v-bind="attrs" #default="{ active }">
          <v-list-item-action>
          <v-checkbox :input-value="active"></v-checkbox>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>
              <v-row no-gutters align="center">
              <v-chip text-color="white" :color="item.color" small
              >{{ item.name }} {{ item.prenom }}</v-chip> 
           
              </v-row>
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </template>
        <!-- HTML that describe how select should render selected items -->
        <template v-slot:prepend-item>
          <v-list-item
            title="Tous" @click="toggleCollab"> Tous
          </v-list-item>
  
          <v-divider class="mt-2"></v-divider>
        </template>
      </v-select></v-col>
      <v-col cols="6">     <v-select 
      v-model="filter.clients" :items="clients" label="Clients" dense chips deletable-chips
     :item-value="'id'" multiple :item-text="'name'"  clearable  >
     <template v-slot:prepend-item>
      <v-list-item
        title="Tous" @click="toggleAllClient"> Tous
      </v-list-item>

      <v-divider class="mt-2"></v-divider>
    </template>
    </v-select>
    </v-col>
     </v-row>
    </v-sheet>
      <v-sheet height="700">
      <v-calendar
            ref="calendar"
            v-model="value"
            :weekdays="weekday"
            :type="type"
            :events="filterEvent"
            :event-overlap-mode="mode"
            :event-overlap-threshold="30"
            :event-color="getEventColor"
            @click:event="showEvent"
            @click:more="showEvents"
            @click:date="showEvents"
            @change="getEvents"
          > <template v-slot:event="{ event }">
            <p v-if="event.statut!='07'">{{ getLabel(event) }}</p>
            <p v-else class="text-decoration-line-through">{{ getLabel(event) }} </p>
        </template></v-calendar>
        <manyTask    v-on:close="refresh"  ref="editTasks"/>
        <oneTask      v-on:close="refresh"   ref="editTask"/>
        <oneOfO   v-on:save="saveOfo" v-on:delete="deleteOfo"  v-on:close="refresh" ref="editOfO"/>
      </v-sheet>
    </div>
  </template>
  
  <script>
  import serviceCollabs from "../service/serviceCollabs"
  import manyTask from "../components/manyTask.vue";
import oneTask from "../components/oneTask.vue";
  import { mapState } from "vuex";
  import oneOfO from "../components/oneOfO.vue";
    export default {
      components: {manyTask,oneTask,oneOfO},
      data: () => ({
        type: 'month',
        types: [
          {id:'month',value:"Mois"},
          {id:'week',value:"Semaine"}
       ],
       filter:{
        collabs:[],
        clients:[]
       },
        mode: 'stack',
        modes: ['stack', 'column'],
        weekday: [ 1, 2, 3, 4, 5, 6,0],
        weekdays: [
          { text: 'Mon - Sun', value: [ 1, 2, 3, 4, 5, 6,0] },
          { text: 'Mon - Fri', value: [1, 2, 3, 4, 5] },
          { text: 'Mon, Wed, Fri', value: [1, 3, 5] },
        ],
        value: '',
        events: [],
        colors: ['blue', 'indigo', 'deep-purple', 'cyan', 'green', 'orange', 'grey darken-1'],
        names: ['Meeting', 'Holiday', 'PTO', 'Travel', 'Event', 'Birthday', 'Conference', 'Party'],
      }),
      computed: {
        filterEvent() {
          let res= this.events;
          if(this.filter.collabs && this.filter.collabs.length>0)
          res= res.filter(item => this.filter.collabs.includes( item.src.collab) || this.filter.collabs.includes( item.src.collabId));
          if(this.filter.clients && this.filter.clients.length>0)
          res= res.filter(item => this.filter.clients.includes( item.src.client));
         return res;
        },
     
    ...mapState({
      tasks: "tasks",
      user:'user',
      lastUpdate:'lastUpdate',
      clients: "clients",
      status:"status",
      typeabs:"typeabs",
      collabs: "collabs",
    }),
  },  watch: {
    'lastUpdate'() {
      this.refresh () ;
    },
    'tasks'() {
      this.refresh () ;
    }, },
      methods:{  deleteOfo(item){
      serviceCollabs.deleteOfo(item.collabId,item.id)
      this.$nextTick(() =>{     this.refresh () ;})
    }
    ,
    getLabel(item){
     var  res="";
      var st = this.status.filter(x=>x.code==item.statut);
      if(st && st.length>0) res =st[0].name +": ";
      res = res+item.name
      return res
    },
    saveOfo(item){
      serviceCollabs.postOfo(item.collabId,
      { 
        id: item.id,
        debut: item.start,
        type: item.type,
        fin: item.end,
        collabId: item.collabId,
        name: item.name,
        charge: item.capa,
        finrecurrence: item.finrecurrence,
        recurrence: item.recurrence,
    
      }).then(() =>{  this.$nextTick(() =>{     this.refresh () ;})})
    
    },
        toggleAllClient () {
   
   var toadd =  this.clients.map((c)=>{return c.id}).slice();
   if(this.filter.clients  && this.filter.clients.length>0 )
   { 
     this.filter.clients.splice(0,this.filter.clients.length)}
   else{
   toadd.forEach((item) => {
       this.filter.clients.push(item)
   })
   
 }
     },
     toggleCollab () {
   
   var toadd =  this.collabs.map((c)=>{return c.id}).slice();
   if(this.collabs.clients  && this.filter.collabs.length>0 )
   { 
     this.filter.collabs.splice(0,this.filter.collabs.length)}
   else{
   toadd.forEach((item) => {
       this.filter.collabs.push(item)
   })
   
 }
     },
        viewDay ({ date }) {
        this.focus = date
        this.type = 'day'
      },
      refresh () {

        this.$nextTick(() =>{ 
         this.getEvents();})
      },
      showEvent ({ nativeEvent, event }) {
        console.log("will show one :"+ nativeEvent)
          console.log(event.src)
          if(event.src.duedate)
          {
            console.log("une tache")
        this.$refs.editTask.open( event.src);}
else { console.log("une absence")
        this.$refs.editOfO.open( event.src)
}
      },
      showEvents    ({ date }) {
        console.log("will show many:"+ date)
        var listTask = this.tasks.filter(t=>t.duedate == date);
        if (listTask.length>0){
          console.log(listTask)
        this.$refs.editTasks.open( listTask);
        }
      },
        getEvents () {
          const events = []
  
     
      
  
          for (var t in this.tasks) {
            var crtTask = this.tasks[t]
          
            const quand = new Date(`${crtTask.duedate}T00:00:00`).getTime()
            const qui  = this.collabs.filter(c=> c.id ==crtTask.collab)[0];
            if(this.user.isAdmin || this.user.id == crtTask.collab)
            events.push({
              name: crtTask.name,
              statut:crtTask.statut,
              start: quand,
              end: quand,
              src: crtTask,
              color: qui ?qui.color : this.colors[this.rnd(0, this.colors.length - 1)],
              timed: false,
            })
          }
          serviceCollabs. getAllOfo().then(response =>
      {    response.data.forEach((item) => {
        let quandOfo = new Date(`${item.debut}T00:00:00`).getTime()
        let quandFinOfo = new Date(`${item.fin}T00:00:00`).getTime()
        let quiOfo  = this.collabs.filter(c=> c.id ==item.collabId)[0];
        let quoiOfo  = this.typeabs.filter(c=> c.code ==item.type)[0];
        if(!quoiOfo)
        quoiOfo={name:""}
        if(!item.name)
        item.name=""
        events.push({
              name:quoiOfo.name+" "+ item.name,
              start: quandOfo,
              end: quandFinOfo,
              src: item,
              color: quiOfo ?quiOfo.color : this.colors[this.rnd(0, this.colors.length - 1)],
              timed: false,
            })
      })
    })
          this.events = events
        },
        getEventColor (event) {
          return event.color
        },
        rnd (a, b) {
          return Math.floor((b - a + 1) * Math.random()) + a
        },
      },
    }
  </script>