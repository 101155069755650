<!-- NEW - add a route to the profile page -->
<template>
  <v-app>
  <v-navigation-drawer
      v-model="drawer"
      fixed
      
      temporary
         style="z-index:200;"
    >
      <v-list 
        nav  v-if="$auth.isAuthenticated && user.idexterne"
        dense
      >
      <v-list-item>
        <v-btn
              color="accent-4"
              text
            
              v-on:click="gotoCalendar"
            > <v-icon >mdi-calendar</v-icon>
              <span >Mon calendrier</span>
      
             
            </v-btn>
            </v-list-item>

        <v-list-item>
  <v-btn
        color="accent-4"
        text
      
        v-on:click="gotoMonPlanning"
      > <v-icon >mdi-calendar</v-icon>
        <span >Mon planning</span>

       
      </v-btn>
      </v-list-item>
          <v-list-item  v-if="$auth.isAuthenticated && user.idexterne">
  <v-btn 
        color="accent-4"
        text
      
        v-on:click="gotoPlanningCollab"
      > <v-icon >mdi-account-switch</v-icon>
        <span >Planning Collab.</span>

       
      </v-btn>
      </v-list-item>
                <v-list-item>
  <v-btn
        color="accent-4"
        text
      
        v-on:click="gotoPlanningClient"
      > <v-icon >mdi-calendar-multiple</v-icon>
        <span >Planning Clients</span>

       
      </v-btn>
      </v-list-item>
              <v-list-item>
  <v-btn
        color="accent-4"
        text
        v-if="user.isAdmin"
        v-on:click="gotoReport"
      > <v-icon >mdi-clipboard-text</v-icon>
        <span >Rapports</span>

       
      </v-btn>
      </v-list-item>
          <v-list-item>
        <v-btn
        color=" accent-4"
        text
        v-if="user.isAdmin"
          v-on:click="gotoAdmin"
      
      >  <v-icon>mdi-lock-alert-outline</v-icon>

        <span>Administration</span>

      
      </v-btn>
          </v-list-item>
      
      </v-list>
    </v-navigation-drawer>
    <v-app-bar     app
  
  >  
  <v-app-bar-nav-icon @click="drawer = true"   class='d-none d-sm-flex'
          v-if="$auth.isAuthenticated && user.idexterne" ></v-app-bar-nav-icon>
     <v-avatar
      color="#ba2430"
      size="50"
         style="z-index:100;"
      v-if="$auth.isAuthenticated && user.idexterne"
       v-on:click="openProfile"
    >
      <span class="white--text text-h5 text-uppercase">{{this.user.initial}}</span>
    </v-avatar>
  <v-avatar
      color="#ba2430"
      size="50"
         style="z-index:100;"
      v-if="!$auth.isAuthenticated || !user.idexterne"
       v-on:click="login"
    >
      <v-icon>   mdi-connection</v-icon>
    </v-avatar>       <h1 class="px-8 pb-4 d-none d-sm-block text-truncate"   > {{subtitle}}</h1>
    <h3 class="px-8 pb-4 d-block d-sm-none text-truncate"  > {{subtitle}}</h3>
 
   <v-spacer></v-spacer>
   <v-spacer></v-spacer>
   <v-tooltip top   v-if="$auth.isAuthenticated && user.idexterne && !user.isAdmin">
        <template v-slot:activator="{ on, attrs }">   <v-btn    
      fab
      dark
      v-on:click="addimprevu"
      v-bind="attrs"
          v-on="on"

      color="orange"><v-icon large>mdi-plus</v-icon> </v-btn>  </template>
        <span>Ajout tache imprevue</span>
      </v-tooltip> 
   <v-speed-dial
      v-model="fab"
      :top="top"
      v-if="$auth.isAuthenticated && user.idexterne && user.isAdmin"
      :direction="direction"
      :open-on-hover="hover"
      :transition="transition"
    >
      <template v-slot:activator>
        <v-btn
          v-model="fab"
          color="blue-darken-2"
          dark
          fab
        >
          <v-icon v-if="fab">
            mdi-close
          </v-icon>
          <v-icon v-else>
            mdi-plus
          </v-icon>
        </v-btn>
      </template>
 
      <v-tooltip top   v-if="$auth.isAuthenticated && user.idexterne && user.isAdmin">
        <template v-slot:activator="{ on, attrs }"> <v-btn    
      fab
      dark
      v-on:click="addimprevu"
      v-bind="attrs"
          v-on="on"
    
      color="orange"><v-icon large>mdi-pencil-plus</v-icon> </v-btn>  </template>
        <span>Ajout tache</span>
      </v-tooltip> 
      <v-tooltip top   v-if="$auth.isAuthenticated && user.idexterne && user.isAdmin">
        <template v-slot:activator="{ on, attrs }">
 
       <v-btn    
       v-bind="attrs"
          v-on="on"
      fab
      dark
      v-on:click="addOfo"

    
      color="orange"><v-icon large>mdi-calendar</v-icon> </v-btn>
    </template>
        <span>Hors entreprise</span>
      </v-tooltip> 
    </v-speed-dial>

<v-btn    
   
      v-if="user.isAdmin && todos && todos.length>0"
      fab
      dark
        v-on:click="switchalert"
      color="red"><v-icon large>mdi-alert-outline</v-icon> </v-btn>
     
     </v-app-bar>

    <v-main class="grey lighten-2">
      <div height="100%">
 <v-alert
         v-for="crtAlert in todos"
      :key="crtAlert.id"
          prominent
      type="error"

      icon="mdi-fire"
      :value="alert"
    >

     <v-row align="center">
        <v-col class="grow">
             {{crtAlert.name}}
        </v-col>
        <v-col  v-if="crtAlert.taskLabel">
          {{crtAlert.taskLabel}}
        </v-col>
        <v-col  v-if="crtAlert.clientLabel">
          Client : {{crtAlert.clientLabel}}
        </v-col>
        <v-col  v-if="crtAlert.collabLabel">
          Collaborateur : {{crtAlert.collabLabel}}
        </v-col>
      </v-row>
     <v-row align="center">
        <v-col class="grow">
                {{crtAlert.text}}
        </v-col>
        <v-col class="shrink">
          <v-btn color="blue darken-2" @click="Clore(crtAlert)" width ="100px">Clore</v-btn>
          <v-btn v-if="crtAlert.task"  color="blue darken-2" @click="GotoTask(crtAlert)" width ="100px">Consulter</v-btn>
        </v-col>
      </v-row>

    </v-alert>
      <router-view   v-if="$auth.isAuthenticated && user.id &&  user.idexterne" :crtEvt='crtEvt' :filters='filters' ref='agendaRef'/>
              <container v-else >
                <v-img 
          :src="require('./assets/kg-1.jpg')"

        />
        <v-img 
        :src="require('./assets/kg-5.jpg')"

      />
      <v-img 
      :src="require('./assets/kg-3.jpg')"

    />
    <v-img 
    :src="require('./assets/kg-4.jpg')"

  />
  <v-img 
  :src="require('./assets/kg-5.jpg')"

/>
<v-img 
:src="require('./assets/kg-6.jpg')"

/>
<v-img 
:src="require('./assets/kg-7.jpg')"

/></container>
        <oneOfO   v-on:save="saveOfo" v-on:delete="deleteOfo" v-on:close="closetask"  ref="editOfO"/>
       <oneTask   ref="editTask" v-on:close="closetask"/>
          </div>
         <v-bottom-navigation    class='d-flex d-sm-none' v-if="$auth.isAuthenticated && user.idexterne"
      fixed
      hide-on-scroll
      
      scroll-target="#hide-on-scroll-example"
    >
     
  <v-btn
        color="deep-purple accent-4"
        text
        v-on:click="gotoMonPlanningSmall"
      > <span >Planning</span>
      <v-icon >mdi-calendar</v-icon>     
      </v-btn>
      <v-btn
      color="deep-purple accent-4"
              text
            
              v-on:click="gotoCalendar"
            >   <span >Calendrier</span>
             <v-icon >mdi-calendar</v-icon>
           
      
             
            </v-btn>

   



         <v-btn       v-if="user.isAdmin"
          color="deep-purple accent-4"
        text
             v-on:click="gotoAdmin"
      ><span >admin</span>
        <v-icon>mdi-lock-alert-outline</v-icon>
      </v-btn>
    </v-bottom-navigation >
   
    <v-snackbar
    v-model="snackbar"
  >
    {{ snackbartext }}

    <template v-slot:actions>
      <v-btn
        color="pink"
        variant="text"
        @click="snackbar = false"
      >
        Close
      </v-btn>
    </template>
  </v-snackbar>
    </v-main>

  
  </v-app>
</template>


<script>
import './assets/style.css';
import router from "@/router";
import { mapState } from "vuex";
import oneTask from "./components/oneTask.vue";

import serviceCollabs from './service/serviceCollabs.js'
import oneOfO from "./components/oneOfO.vue";
export default {
  name: "app",
  components: {oneTask,oneOfO},
  computed: {
    ...mapState({
      alert:"alert",
      snackbartext:'snackbartext',
    
         alerts:"alerts",
         todos:"todos",
      user:"user",
      subtitle:"subtitle",
    }),
  },
     mounted() { 
   
     this.$store.commit( 'UPDATE_SUBTITLE', "Kermat Gestion")
      this.$nextTick(() =>{ this.updateComponents();
  });
    },
  
   methods: {
    closetask(){this.dialog=false;
      this.$store.commit('REFRESH',new Date())},
    Clore(anAlert){
      anAlert.done=true
      this.$store.commit('UPDATE_ALERTE',anAlert)
   },
   addOfo( ){
    let start = new  Date();

    this.$refs.editOfO.open( {

id : null,
start : start.getFullYear() +'-' +(start.getMonth()+1) +'-'+start.getDate(),
end :  start.getFullYear() +'-' +(start.getMonth()+1) +'-'+start.getDate(),
name :  this.currentName,
capa:  8

});},
  
    deleteOfo(item){
      serviceCollabs.deleteOfo(this.$route.params.id,item.id).then(() =>{
      serviceCollabs.getOfoById(this.$route.params.id).then(responseOfo =>{
          this.dataSource = responseOfo.data.map((ev)=>
           {return {id: ev.id,
          name : ev.name,
          startDate : new Date(ev.debut), 
          endDate : new Date(ev.fin),
          capa : ev.charge,
          type: ev.type
            }}
          );
  
            })})
   
    },

    saveOfo(item){
      serviceCollabs.postOfo(this.$route.params.id,
      { id: item.id,
        debut: item.start,
        type: item.type,
        fin: item.end,
       collabId: item.collabId,
          name: item.name,
        charge: item.capa,
        finrecurrence: item.finrecurrence,
        recurrence: item.recurrence,
    
      }).then(response=>{
        if(response.data.erreurs)
        {
          this.$store.state.snackbartext=''
          response.data.erreurs.forEach((item) => {
            this.$store.state.snackbartext+=item.message
       
            
          })

        
        }
      })
      this.dataSource.push(
{
      id: item.id,
          name: item.name,
          color: item.color,
          startDate: new Date(item.start), 
          endDate: new Date( item.end),
          capa: item.capa,
          type: item.type
})

    },
   addimprevu( ){
    let start = new  Date();
    this.$refs.editTask.createImpromptu( {collab:this.user.id , 
    duedate: start.getFullYear() +'-' +(start.getMonth()+1) +'-'+start.getDate()});},
    GotoTask(anAlert){
      this.$refs.editTask.open(  {id: anAlert.task});
    },
       login() {
      this.$auth.loginWithRedirect();
    },   
    logout() {
      this.$auth.logout({
        returnTo: window.location.origin
      });},
      gotoCalendar(){
        this.$store.commit('UPDATE_ALERT',  false);
            router.push({ name: "calendrier" , params: { id:  this.user.id } });

     },
      gotoReport(){
        this.$store.commit('UPDATE_ALERT',  false);
            router.push({ name: "report" , params: { id:  this.user.id } });

     },
      gotoAdmin(){
       this.$store.commit('UPDATE_ALERT',  false);
            router.push({ name: "admin" });

     },
     openProfile(){
      this.$store.commit('UPDATE_ALERT',  false);
            router.push({ name: "profile" , params: { id:  this.user.id } });

     },
     gotoMonPlanning(){
      this.$store.commit('UPDATE_ALERT',  false);
           router.push({ name: "monPlanning" , params: { id: this.user.id } });
     },
     gotoMonPlanningSmall(){
      this.$store.commit('UPDATE_ALERT',  false);
           router.push({ name: "monPlanningSmall" , params: { id: this.user.id } });
     },
     gotoPlanningCollab(){
      this.$store.commit('UPDATE_ALERT',  false);
           router.push({ name: "planningCollab" , params: { id: this.user.id } });
     },
     gotoPlanningClient(){
      this.$store.commit('UPDATE_ALERT',  false);
           router.push({ name: "planningClient" , params: { id: this.user.id } });
     },
     gotoHome(){
      this.$store.commit('UPDATE_ALERT',  false);
           router.push({ name: "home" , params: { id: this.user.id } });
     },
          gotoResultats(){
            this.$store.commit('UPDATE_ALERT',  false);
           router.push({ name: "historiques" , params: { id: this.user.id } });
     },
     gotoPalmares(){
      this.$store.commit('UPDATE_ALERT',  false);
           router.push({ name: "palmares" , params: { id: this.user.id } });
     },
    gotoStat(){
      this.$store.commit('UPDATE_ALERT',  false);
            router.push({ name: "statistiques" , params: { id:  this.user.id } });
           },
    validateProfile(){
     
         this.$store.commit('UPDATE_USER',   this.user );
       this.showprofile = false;
     },
    closeProfile(){
       this.showprofile = false;
     },
    clear: function() {
        this.filters.annee='2023';
        this.filters.mois='';
        this.filters.departement='';
    },
    switchalert: function() {
        this.$store.commit('UPDATE_ALERT',   !this.alert);
    },
    updateComponents: function() {
 
            }
  }, watch: {
    'snackbartext'(val) {
      if(val) {this.snackbar=true}
    }},
  data() {
    return {
   
  dialog:false,
      crtEvt: {
        },
      filters:
      {
        annee:new Date().getFullYear().toString(),
        mois:'',
        departement:'',

      },
      snackbar :false,
      drawer: false,
      group: null,
      direction: "bottom",
      fab: false,
      pickMonth: false,
      fling: false,
      hover: true,
      tabs: null,
      top: false,
      showprofile:false,
      right: false,
      bottom: true,
      left: false,
      transition: "slide-y-reverse-transition",
      tab: null,
   };
  },
};
</script>
