<template>
  <v-sheet>
    <v-row>
      <v-col :cols="3">
        <v-list two-line flat>

            <template v-for="collab in filtrableCollab">
            <v-list-item :key="collab.name">
              <template>
                <v-list-item-action>
                  <v-checkbox v-model="collab.active"></v-checkbox>
                </v-list-item-action>
                <v-list-item-content>
                  <v-list-item-title>
                    <h1>
                      <v-chip style="width: 100%;" :color="collab.color" dark>{{ collab.name }} </v-chip>
                    </h1>
                  </v-list-item-title>

                </v-list-item-content>
              </template>
            </v-list-item>
          </template>
        </v-list>
      </v-col>
      <v-col :cols="9">
        <v-data-table :items="weekarray" item-key="name" disable-pagination hide-default-footer class="elevation-1">
        </v-data-table>

        <v-data-table :items="weekarray" item-key="name" disable-pagination hide-default-footer class="elevation-1">
          <v-btn  v-if="user.isAdmin" class="mx-2 center" fab dark center small color="primary">
            <v-icon dark> mdi-plus </v-icon>
          </v-btn>
          <template v-slot:header>
            <thead>
              <tr>
                <th>Lundi</th>
                <th>Mardi</th>
                <th>Mercredi</th>
                <th>Jeudi</th>
                <th>Vendredi</th>
                <th>Samedi</th>
                <th>Dimanche</th>
              </tr>
            </thead>
          </template>

          <template v-slot:item="{ item }">
            <tr >
              <td v-for=" crtday in item" :key="crtday.date"> 

                <v-card class="mx-auto rounded-lg" v-if="crtday.numero">
                  <v-card-text>
                    <v-row>
                      <v-col justify-start>{{ crtday.numero }} </v-col>
                      <v-col justify-end>{{  crtday.taches.length}} </v-col>
                    </v-row>
                    <v-chip  dark v-for="client in daylyCollab(crtday.taches)" :key="client.id"
                    v-on:click="GotoTask(daylytaskByCollab(client,crtday.taches))"
               :color="client.color" x-small>{{daylytaskByCollab(client,crtday.taches).length}}</v-chip>
               <v-chip  v-if="user.isAdmin" x-small @click="addone(crtday.date) ">  <v-icon  x-small>mdi-plus</v-icon></v-chip>
                  </v-card-text>
                </v-card>
              </td>

            </tr>

          </template>
        </v-data-table>
      </v-col>
    </v-row>
    <manyTask   ref="editTasks"/>
      <oneTask   ref="editTask"/>
  </v-sheet>
</template>

<script>

import { mapState } from "vuex";
import manyTask from "../components/manyTask.vue";
import oneTask from "../components/oneTask.vue";
export default {
   
  props: {

    filter: {
      type: Object,
      default: () => ({
        annee: new Date().getFullYear().toString(),
        mois: "",
        semaine: ""
      }),
    },
  },
  components: {manyTask,oneTask},
  computed: {
    ...mapState({
      tasks: "tasks",
      clients: "clients",
      user:"user",

    }),
    weekarray() {
      let res = []
      if (this.filter && this.filter.annee && this.filter.mois) {

        var crtWeek = [];
        res.push(crtWeek);

        var premier = new Date(this.filter.annee + '-' + this.filter.mois + '-1').getDay();
        if (premier == 0)
          premier = 7;
        for (let index = 1; index < premier; index++) {
          crtWeek.push({date: this.filter.annee + '-' + this.filter.mois + '--' + index});
        }
        for (let index = 1; index <= 31; index++) {

          if (this.dateIsValid(new Date(this.filter.annee + '-' + this.filter.mois + '-' + index))) {

            crtWeek.push({ date: this.filter.annee + '-' + this.filter.mois + '-' + index, 
            taches: this.daylytask(new Date(this.filter.annee + '-' + this.filter.mois + '-' + index), this.filteredTask) , numero:index
             });
            if ((new Date(this.filter.annee + '-' + this.filter.mois + '-' + index)).getDay() == 0) {
            
             crtWeek = []
              res.push(crtWeek);
            }
          }
        }
      }
    
      return res;
    },
    filteredTask() {
      var res = this.tasks;

      if (this.filter && this.filter.annee)
        res = res.filter((tache) => tache.annee == this.filter.annee);
      if (this.filter && this.filter.mois)
        res = res.filter((tache) => tache.mois == this.filter.mois);
      res = res.filter((tache) => this.filtrableCollab.filter(collab => collab.active).map(collab => collab.id).includes(tache.client));
    
      
      return res;
    },
  },
   mounted() {
    this.filtrableCollab = this.initFiltrableCollab();

  },
  methods: {
    addone( when){ this.$refs.editTask.open( {duedate:when});},
     GotoTask(tasks){
    
      this.$refs.editTasks.open(  tasks);
    },
      initFiltrableCollab() {
      var res = this.clients.map(x => ({
        ...x,
        active: true
      }))
  
      return res;
    },
    formatdate(unedate){
    var datestring = unedate.getFullYear()+ "-" + ("0"+(unedate.getMonth()+1)).slice(-2) + "-" + ("0" + unedate.getDate()).slice(-2)  ;
    return datestring
    },
     daylyCollab( taches){
      var res=[]
      if(taches)
      {var listeemail = taches.map(tache =>tache.client );
      res = this.clients.filter(collab => listeemail.includes(collab.id)>0);}
      return res;

     },
     daylytaskByCollab(collab, taches){
      var res =[]
      if(taches)
      res = taches.filter(tache => tache.client ==collab.id );
      return res;
     },

    daylytask(thedate, taches){
      var res=[]
    
      res = taches.filter(tache => tache.duedate == this.formatdate(thedate))
 
      return res;
    },
    dateIsValid(date) {
      return date instanceof Date && !isNaN(date);
    },
    /* return groupBy(getters.sortedEvents, evt => evt.startdate.split("/")[2] + "-" + evt.startdate.split("/")[1]);*/
    groupBy(list, keyGetter) {
      const map = new Map();
      list.forEach((item) => {
        const key = keyGetter(item);
        const collection = map.get(key);
        if (!collection) {
          map.set(key, [item]);
        } else {
          collection.push(item);
        }
      });
      return map;
    },

  },
  data() {
    return {
  filtrableCollab: null
    
    }
  }
}
</script>
