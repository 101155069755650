<template>

  <calendarCommon/>

</template>

<script>

import calendarCommon from '../components/calendarCommon'
export default {
mounted() {
   this.$store.commit( 'UPDATE_SUBTITLE', "Mon calendrier")
      
},
name: "calendar-view",
components: {
  calendarCommon
},
methods: {

}
};
</script>