import myApi from './myApi.js'

export default {
  
  getTodo() {
    return   myApi.get('Todo')
  },
  get() {
    return   myApi.get('Alertes')
  },
  post(anAlerte) {
    return   myApi.post('Alertes',anAlerte)
  },
  delete(id) {
    return   myApi.delete('Alertes/'+id)
  },
  getById(id) {
    return   myApi.get('Alertes/'+id)
  },
 

}