
  <template>
    <v-container>  <v-sheet tile class="d-flex">

    
      <v-select
        :items="years"
        dense
        outlined
        v-on:change="search"
        v-model="filter.annee"
        :clearable="true"
        hide-details
        class="ma-2"
        label="année"
       :key="filter.annee"
      
      ></v-select>
      <v-select
        :items="mois"
        v-on:change="search"
        dense
        :clearable="true"
        outlined
        hide-details
        v-model="filter.mois"
          :key="filter.mois"
             :reduce="op => op.code"
        class="ma-2"
        :item-text="'name'"
        :item-value="'code'"
        label="mois"
      ></v-select>
      <v-text-field
      v-on:change="search"
        dense
        outlined
        :clearable="true"
        class="ma-2"
        v-model="filter.semaine"
        type="number"
        label="semaine"
      ></v-text-field>

   
    </v-sheet>
      <v-data-table
      :headers="headers"
      :items="taches"
      item-key="name"
      class="elevation-1"
      locale="fr"
      show-group-by
    >
    <template v-slot:[`item.actions`]="{ item }">
      <v-icon
        small
        class="mr-2"
        @click="GotoTask(item)"
      >
      mdi-magnify
      </v-icon>
    
    </template>
    <template v-slot:top>
        <v-row>
          <v-col cols="12">
            <v-btn
      class="mx-2 float-right"
      fab
      dark
      color="red" 
    >   <v-icon  @click="download">
      mdi-file-download-outline
              </v-icon></v-btn>
          </v-col>
  
        </v-row>
      </template>
    
    <template v-slot:no-data>
     pas de tache
    </template> 
    </v-data-table>
    </v-container>
    </template>
    
  <script>
  import { mapState } from "vuex";
  import serviceReport from '../service/serviceReport.js'
    export default {
      methods: {
        search(){
        serviceReport.postProd(this.filter).then(response => {this.tachesint = response.data})
      },
      download(){

        this.$nextTick(() =>{     serviceReport.downloadProd({});})
      },GotoTask(atask){
      this.$refs.editTask.open(  {id: atask.IdCollab});
    }},
      mounted() {
       this.search();
    },
      data () {
        return {
          tachesint:[],
          filter:{ 
                annee:new Date().getFullYear().toString(),
                mois:"",
                semaine:""},
          exampleRules: [ 
                v => ( v && v >= 52 ) || "le numero de semaine ne peux pas etre > 52",
                v => ( v && v <= 1 ) || "le numero de semaine ne peux pas etre < 1",
        ]
        }
      }, 
  
  
      computed: {
        taches() {return this.tachesint},
        ...mapState({
            mois: "mois",
            years: "years",
  
      }),
        headers () {
          return [
            { text: 'Collaborateur',    value:    'name',},
            { text: 'Nb taches',   value:    'tache' },
            { text: 'Derive moy.',   value:    'derive' },
            { text: 'Nb retard',   value:    'retard' },
            { text: 'Actions', value: 'actions', sortable: false , groupable:false},

          ]
        },
      }}
  
      </script>