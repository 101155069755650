
import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'

//import { v4 as uuidv4 } from 'uuid';
import router from "@/router";
import serviceCollabs from '../service/serviceCollabs.js'
import serviceClients from '../service/serviceClients.js'
import serviceCalendrier from '../service/serviceCalendrier.js'
import serviceAlertes from '../service/serviceAlertes.js'
import serviceTache from '../service/serviceTache.js'
Vue.use(Vuex)

// Create a new store instance.
const store = new Vuex.Store({
  state() {
    return {
      snackbartext:'',
      timePick:  [{label : "15'",time:0.25},
      {label : "30'",time:0.5},
      {label : "45'",time:0.75},
      {label : "1h",time:1},
      {label : "1h 15'",time:1.25},
      {label : "1h 30'",time:1.5},
      {label : "1h 45'",time:1.75},
      {label : "2h",time:2},
      {label : "2h 15'",time:2.25},
      {label : "2h 30'",time:2.5},
      {label : "2h 45'",time:2.75},
      {label : "3h",time:3},
      {label : "3h 15'",time:3.25},
      {label : "3h 30'",time:3.5},
      {label : "3h 45'",time:3.75},
      {label : "4h",time:4},
      {label : "4h 15'",time:4.25},
      {label : "4h 30'",time:4.5},
      {label : "4h 45'",time:4.75},
      {label : "5h",time:5},
      {label : "6h",time:6},
      {label : "7h",time:7},
      {label : "8h",time:8}],
      subtitle:"",
      alert: false,
      token: "",
      user:{},
      baseUrl: "https://protofishapi.herokuapp.com/",
      clients: [],  
      typeabs: [ 
        { name: "Ecole", code: "01",color:"#99ffe6" },
        { name: "Malade", code: "02",color:"#61bdac" },
        { name: "Vacances", code: "03",color:"#cdff64" },
        { name: "Autres", code: "04",color:"#cf4474" },],
      recurrence: [ 
        { name: "Aucune", code: "00" },
      { name: "Hebdomadaire", code: "02" },
      { name: "Mensuel", code: "03" },
      { name: "Premier jour ouvré du mois", code: "05" },
      { name: "Dernier jour ouvré  du mois", code: "06" },
      { name: "Annuel", code: "04" },
      { name: "Trimestriel", code: "07" },
      { name: "Quadrimestriel", code: "08" },
      { name: "Semestriel", code: "09" },],
      status: [    { name: "Brouillon", code: "01", adminOnly:true },
      { name: "A affecter", code: "02", adminOnly:true },
      { name: "A faire", code: "03", adminOnly:true },
      { name: "En cours", code: "04", adminOnly:false },
      { name: "A replanifier", code: "05", adminOnly:true },
      { name: "Bloqué", code: "06", adminOnly:false },
      { name: "Fait", code: "07", adminOnly:false },
    ],
    lastUpdate:"",
      collabs: [],
      feries: [],
      tasks: [],
      alerts: [],
      todos:[],
      title: "",
      mois: [
        { name: "Janvier", code: "01" },
        { name: "Fevrier", code: "02" },
        { name: "Mars", code: "03" },
        { name: "Avril", code: "04" },
        { name: "Mai", code: "05" },
        { name: "Juin", code: "06" },
        { name: "Juillet", code: "07" },
        { name: "Aout", code: "08" },
        { name: "Septembre", code: "09" },
        { name: "Octobre", code: "10" },
        { name: "Novembre", code: "11" },
        { name: "Décembre", code: "12" }
      ],
      types: [
        { name: "Annee", code: "year" }, 
        { name: "Mois", code: "month" },
         { name: "Semaine", code: "week" },
        { name: "Liste", code: "list" }],
      years: ["2028","2027","2026","2025","2024","2023", "2022"],
  
    }
  },

  mutations: { //Mutations are like setters
  LOAD_TASKS: (state, tasks) => { 
      state.tasks =tasks;
    },  REFRESH: (state, lastUpdate) => { 
      state.lastUpdate =lastUpdate;
    },

    UPDATE_ALERT: (state, newAlert) => { //capitalization is good-practice for vuex-mutations
      state.alert = newAlert;
    },
    UPDATE_SUBTITLE: (state, subtitle) => {
      state.subtitle=subtitle },
    LOAD_COLLAB: (state, collabs) => { 
      state.collabs =collabs;
    },

    LOAD_TODOS: (state, todos) => { 
      state.todos =todos;
    },
    LOAD_ALERTES: (state, alerts) => { 
      state.alerts =alerts;
    },
    LOAD_FERIES: (state, feries) => { 
      state.feries =feries;
    },
    LOAD_CLIENTS: (state, clients) => { 
      state.clients =clients;
    },
    ADD_USER: (state, user) => { //capitalization is good-practice for vuex-mutations
      state.collabs.push(user);
    },
    SET_TOKEN: (state, token) => {
      state.token = token
      axios.defaults.headers.common = { 'Authorization': `bearer ${token}` }
    },

    SET_CURRENTUSER: (state, user) => { //capitalization is good-practice for vuex-mutations

  state.user = user
if(user.useCal)
{
  router.push({ name: "calendrier" });
}
else{
  router.push({ name: "monPlanning" });
}
      
    },
    UPDATE_COLLAB: (state,collab)=>{
      serviceCollabs.post(collab).then(() => {   
        serviceCollabs.get().then(response => 
          {   state.collabs = response.data;})
        });

    },
    ADD_COLLAB: (state,collab)=>{
      serviceCollabs.post(collab).then(() => {   
        serviceCollabs.get().then(response => 
          {   state.collabs = response.data;})
        });

    },
    DELETE_COLLAB: (state, id) => {

      serviceCollabs.delete(id).then((ret) => {   
        if(ret.data.erreurs)
        {
          state.snackbartext =''
          ret.data.erreurs.forEach((item) => {
            state.snackbartext += ' '+item.message;
          })
        }
        serviceCollabs.get().then(response => 
          {   state.collabs = response.data;})
        });
    }
    ,
    UPDATE_CLIENT: (state,client)=>{
      serviceClients.post(client).then(() => {   
        serviceClients.get().then(response => 
          {   state.clients = response.data;})
        });

    },
    ADD_CLIENT: (state,client)=>{
      serviceClients.post(client).then(() => {   
        serviceClients.get().then(response => 
          {   state.clients = response.data;})
        });

    },
    DELETE_CLIENT: (state, id) => {
    
      serviceClients.delete(id).then(() => {   
        serviceClients.get().then(response => 
          {   state.clients = response.data;})
        });
    }
    ,
    UPDATE_ALERTE: (state,alerte)=>{
      serviceAlertes.post(alerte).then(() => {   
        serviceAlertes.get().then(response => {store.commit('LOAD_ALERTES',response.data);})
        serviceAlertes.getTodo().then(response => {store.commit('LOAD_TODOS',response.data);})
        });

    },
    ADD_ALERTE: (state,alerte)=>{
      serviceAlertes.post(alerte).then(() => {   
        serviceAlertes.get().then(response => {store.commit('LOAD_ALERTES',response.data);})
        serviceAlertes.getTodo().then(response => {store.commit('LOAD_TODOS',response.data);})
        });

    },
    DELETE_ALERTE: (state, id) => {
    
      serviceAlertes.delete(id).then(() => {   
        serviceAlertes.get().then(response => {store.commit('LOAD_ALERTES',response.data);})
        serviceAlertes.getTodo().then(response => {store.commit('LOAD_TODOS',response.data);})
        });
    }
    ,
    UPDATE_DAYOFF: (state,dayoff)=>{
      serviceCalendrier.post(dayoff).then(() => {   
        serviceCalendrier.get().then(response => 
          {   state.feries = response.data;})
        });

    },
    ADD_DAYOFF: (state,dayoff)=>{
      serviceCalendrier.post(dayoff).then(() => {   
        serviceCalendrier.get().then(response => 
          {   state.feries = response.data;})
        });

    },
    DELETE_DAYOFF: (state, id) => {
    
      serviceCalendrier.delete(id).then(() => {   
        serviceCalendrier.get().then(response => 
          {   state.feries = response.data;})
        });
    },
   REFRESH_TASK: ()=>{    serviceTache.get().then(response => {store.commit('LOAD_TASKS',response.data);})
   serviceAlertes.get().then(response => {store.commit('LOAD_ALERTES',response.data);})
   serviceAlertes.getTodo().then(response => {store.commit('LOAD_TODOS',response.data);})},
    UPDATE_TASK: (state,aTask)=>{
      state.lastUpdate= new Date();
      serviceTache.post(aTask).then(() => {   
        serviceTache.get().then(response => {store.commit('LOAD_TASKS',response.data);})
        serviceAlertes.get().then(response => {store.commit('LOAD_ALERTES',response.data);})
        serviceAlertes.getTodo().then(response => {store.commit('LOAD_TODOS',response.data);})
        });


    },
    DELETE_TASK: (state,id)=>{
      serviceTache.delete(id).then(() => {   
        serviceTache.get().then(response => {store.commit('LOAD_TASKS',response.data);})
        });

    }
  },
  getters: {
  

}}
)

export default store
serviceCollabs.get().then(response => { store.commit('LOAD_COLLAB',response.data);})
serviceClients.get().then(response => {store.commit('LOAD_CLIENTS',response.data);})
serviceCalendrier.get().then(response => {store.commit('LOAD_FERIES',response.data);})
serviceTache.get().then(response => {store.commit('LOAD_TASKS',response.data);})
serviceAlertes.get().then(response => {store.commit('LOAD_ALERTES',response.data);})
serviceAlertes.getTodo().then(response => {store.commit('LOAD_TODOS',response.data);})
/*
function groupBy(list, keyGetter) {
  const map = new Map();
  list.forEach((item) => {
    const key = keyGetter(item);
    const collection = map.get(key);
    if (!collection) {
      map.set(key, [item]);
    } else {
      collection.push(item);
    }
  });
  return map;
}
*/


