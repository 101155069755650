<template>
  <div>
    <v-data-table
      :headers="headers"
      :items="clients"
      item-key="name"
      class="elevation-1"
      locale="fr"
  show-group-by
    >
    
    <template v-slot:[`item.actions`]="{ item }">
      <v-icon
        small
        class="mr-2"
        @click="editItem(item)"
      >
        mdi-pencil
      </v-icon>
      
    </template>
    <template v-slot:top>
        <v-row>
          <v-col cols="12">
            <v-btn
      class="mx-2 float-right"
      fab
      dark
      color="red" 
    >   <v-icon @click="addone">
                mdi-plus
              </v-icon></v-btn>
          </v-col>

        </v-row>
      </template>
    
    <template v-slot:no-data>
     pas de collaborateurs
    </template> 
    </v-data-table>
  </div>
</template>

<script>
import { mapState } from "vuex";
import router from "@/router";
  export default {
    data () {
      return {
        search: '',
        calories: '',
      }
    }, 


    computed: {
         ...mapState({
      clients: "clients",


    }),
      headers () {
        return [
          { text: 'Nom',value: 'name',},
          { text: 'Couleur', value: 'color' },
          { text: 'Actions', value: 'actions', sortable: false, groupable:false },
        ]
      },
    },
    methods: {
      addone(){

        router.push({ name: "addClients" });
      },
      editItem (item) {
      
        router.push({ name: "clients", params: { id: item.id } });
      },

    },
  }
</script>