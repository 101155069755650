<template> <v-container>
  <v-row dense   class="pb-16">
    <v-col cols="12">
      <v-card
     
        
      >
        <v-card-title class="text-h5" >
          Edition Collaborateur Hors Entreprise : {{internalItem.prenom}} {{internalItem.name}}
        </v-card-title>
        <v-card-subtitle class="text-h5" >
        <v-chip  :key="crtype.code" v-for="crtype in typeabs" :color="crtype.color"> {{crtype.name}} </v-chip>
      </v-card-subtitle>

        <v-card-text>
          <Calendar :display-week-number="true"
          :render-style="style"
          :enable-range-selection="true"
            :data-source="dataSource"
            :enable-context-menu="true"
            :context-menu-items="contextMenuItems"
            @select-range="selectRange"    
         language="fr"></Calendar>
        </v-card-text>
       <v-card-actions>
          <v-btn text  @click="save">
           Sauver
          </v-btn>
          <v-btn text  @click="close">
           Annuler
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-col></v-row>

  <oneOfO   v-on:save="saveOfo" v-on:delete="deleteOfo"  ref="editOfO"/>
    </v-container></template>
    <script>

import serviceCollabs from '../service/serviceCollabs.js'
import oneOfO from "../components/oneOfO.vue";
import { v4 as uuidv4 } from "uuid";
import router from "@/router";
import Calendar from 'v-year-calendar';
import 'v-year-calendar/locales/v-year-calendar.fr';
import { mapState } from "vuex";

  export default {
    components: {
    Calendar,oneOfO
  }, 
    mounted() {
      if(this.$route.params.id){
      serviceCollabs.getById(this.$route.params.id).then(response =>
      {
       
        this.internalItem=Object.assign({}, response.data);
        serviceCollabs.getOfoById(this.$route.params.id).then(responseOfo =>{
          this.dataSource = responseOfo.data.map((ev)=>
           {
            var res = this.typeabs.filter((x)=>x.code == ev.type)
            var color = res? res.length>0?res[0].color:"#e7b6c1":"#e7b6c1"
            return {id: ev.id,
              collabId:ev.collabId,
          name : ev.name,
          color: color,
          startDate : new Date(ev.debut), 
          endDate : new Date(ev.fin),
          capa : ev.charge,
          type: ev.type
}}
          );
       
            })
   
      })}
      else{
        this.internalItem={
          id: uuidv4(),
          color : "#121212",
          name : "",
          prenom:"",
          email:"",
          capacite:"7"}
        }
      
    },
    data: () => ({
      show: false,
      style: 'background',
      currentId: null,
      currentStartDate: null,
      currentEndDate: null,
      currentName: null,
      currentLocation: null,
      dialog: false,
      dataSource: [],
      internalItem: {},
      contextMenuItems: [
        {
          text: "Update",
          click: evt => {
            this.currentId = evt.id;
            this.currentStartDate = evt.startDate.toISOString().substring(0, 10);
            this.currentEndDate = evt.endDate.toISOString().substring(0, 10);
            this.currentName = evt.name;
            this.currentLocation = evt.location;

            this.show = true;
          }
        },
        {
          text: "Delete",
          click: evt => {

            this.dataSource = this.dataSource.filter(item => item.id != evt.id);
          }
        }
      ]
    }), 
      watch: {
     dialog(val) {
        if(!val)
        this.$emit("close")
    }},
    methods: {
      selectRange: function(e) {
      this.currentId = null;
      this.currentName = null;

{
        var tmpStartDate =new Date( e.startDate);
            var tmpEndDate =new Date(  e.endDate);
        tmpStartDate.setDate(e.startDate.getDate() + 1)
        tmpEndDate.setDate(e.endDate.getDate() + 1)
       
      this.currentStartDate =tmpStartDate.toISOString().substring(0, 10);
      this.currentEndDate = tmpEndDate.toISOString().substring(0, 10);
      }
      

      if( this.dataSource.filter((event)=>event.startDate.toISOString().substring(0, 10) ==  this.currentStartDate && event.endDate.toISOString().substring(0, 10) ==  this.currentEndDate).length >0 ){
   
        var crtevt= this.dataSource.filter((event)=>event.startDate.toISOString().substring(0, 10) ==  this.currentStartDate && event.endDate.toISOString().substring(0, 10) ==  this.currentEndDate)[0]
        this.$refs.editOfO.open(   {

id : crtevt.id,
collabId: crtevt.collabId,
debut : this.currentStartDate,
fin :   this.currentEndDate,
name :   crtevt.name,
charge:  crtevt.capa,
type:crtevt.type
});
      }
      else
      {
      this.$refs.editOfO.open( {

        id : this.currentId,
        collabId: this.$route.params.id,
        debut : this.currentStartDate,
        fin :   this.currentEndDate,
        name :  this.currentName,
       charge:  this.internalItem.capacite
  
      });}
    },
      supprime() {
       
    },
      close() {
   router.go(-1)
    },
    deleteOfo(item){
      serviceCollabs.deleteOfo(this.$route.params.id,item.id).then(() =>{
      serviceCollabs.getOfoById(this.$route.params.id).then(responseOfo =>{
          this.dataSource = responseOfo.data.map((ev)=>
           {return {id: ev.id,
          name : ev.name,
          startDate : new Date(ev.debut), 
          endDate : new Date(ev.fin),
          capa : ev.charge,
          type: ev.type
            }}
          );
  
            })})
   
    },

    saveOfo(item){
      serviceCollabs.postOfo(this.$route.params.id,
      { id: item.id,
        debut: item.debut,
        type: item.type,
        fin: item.fin,
       collabId: this.$route.params.id,
          name: item.name,
        charge: item.capa,
        finrecurrence: item.finrecurrence,
        recurrence: item.recurrence,
    
      }).then(response=>{
        if(response.data.erreurs)
        {
          this.$store.state.snackbartext=''
          response.data.erreurs.forEach((item) => {
            this.$store.state.snackbartext+=item.message
       
            
          })

        
        }
      })
      this.dataSource.push(
{
      id: item.id,
          name: item.name,
          color: item.color,
          startDate: new Date(item.debut), 
          endDate: new Date( item.fin),
          capa: item.capa,
          type: item.type
})

    },
    save() {
   
  
   router.go(-1)

    },
    open() {
      this.dialog=true;
    }
  },
  computed: {
    ...mapState({
      tasks: "tasks",
      typeabs:"typeabs",
      snackbartext:'snackbartext',
      collabs: "collabs",
      user:"user",
    }),},
  props: {
     id: {
      type:String,
      default: "",
    },
    editedItem: {
      type: Object,
      default: () => ({}),
    },
  },

  }
</script>