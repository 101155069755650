import myApi from '../service/myApi.js'

export default {
  GetByEmail(email){
    return   myApi.get('Collabs/byMail/'+email)
  },
  GetByExt(idExterne){
    return   myApi.get('Collabs/GetByExt/'+idExterne)
  },
  CollabsAvailable(when,  howmuch,what){
    return   myApi.post('CollabsAvailable',{ when: when,  howmuch: howmuch, what:what})
  },
  getAllOfo() {
    return   myApi.get('Ofo')
  },
  get() {
    return   myApi.get('Collabs')
  },
  post(aCollab) {
    return   myApi.post('Collabs',aCollab)
  },
  delete(id) {
    return   myApi.delete('Collabs/'+id)
  },
  getById(id) {
    return   myApi.get('Collabs/'+id)
  },
  getOfoById(id) {
    return   myApi.get('Collabs/'+id+'/Ofo')
  },
  postOfo(id,anOfo) {
    return   myApi.post('Collabs/'+id+'/Ofo',anOfo)
  },
  deleteOfo(id,idOfo) {
    return   myApi.delete(('Collabs/'+id+'/Ofo/')+idOfo)
  },
  getTravelById(id) {
    return   myApi.get('Collabs/'+id+'/Travel')
  },
  postTravel(id,anOfo) {
    return   myApi.post('Collabs/'+id+'/Travel',anOfo)
  },
  deleteTravel(id,idOfo) {
    return   myApi.delete(('Collabs/'+id+'/Travel/')+idOfo)
  },
  getHomeofficeById(id) {
    return   myApi.get('Collabs/'+id+'/Homeoffice')
  },
  postHomeoffice(id,anOfo) {
    return   myApi.post('Collabs/'+id+'/Homeoffice',anOfo)
  },
  deleteHomeoffice(id,idOfo) {
    return   myApi.delete(('Collabs/'+id+'/Homeoffice/')+idOfo)
  },

}