<template>

    <myPlanning/>

</template>

<script>

import myPlanning from '../components/myPlanning'
export default {
  mounted() {
     this.$store.commit( 'UPDATE_SUBTITLE', "Mon Planning")
        
  },
  name: "planning-view",
  components: {
    myPlanning
  },
  methods: {

  }
};
</script>