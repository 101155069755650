<template> <v-container>
  <v-row dense   class="pb-16">
    <v-col cols="12">
      <v-card
     
        
      >
        <v-card-title class="text-h5" >
          Edition Collaborateur
        </v-card-title>
        <v-card-subtitle class="text-h5" >
        <v-chip :color="internalItem.color" dark> {{internalItem.prenom}} {{internalItem.name}}</v-chip>   
        </v-card-subtitle>

        <v-card-text>
 <v-row> 
  <v-col cols="12" md="9"> <v-row> 
  <v-col cols="12" sm="6"><v-text-field
                  
                      v-model="internalItem.name"
                      label="Nom"

                    ></v-text-field>
                  </v-col> 
                  <v-col cols="12" sm="6">
                    <v-text-field
                  
                  v-model="internalItem.prenom"
                  label="Prenom"

                ></v-text-field>    </v-col> 
                <v-col cols="12" sm="6">
                 <v-text-field
                  
                  v-model="internalItem.email"
                  label="Email"

                ></v-text-field> 
              </v-col> <v-col cols="6" sm="3">
                <v-text-field
                  
                  v-model="internalItem.capacite"
                  label="Capacité (heures)"

                ></v-text-field>    </v-col> <v-col cols="6" sm="3">
                <v-checkbox
                               
                               v-model="internalItem.isAdmin"
                               label="Est admin"
                               ></v-checkbox>    </v-col>
                               <v-col cols="6" sm="3">
                                <v-checkbox
                                               
                                               v-model="internalItem.useCal"
                                               label="Calendrier par defaut"
                                               ></v-checkbox>    </v-col>  </v-row></v-col>
                               
                               <v-col cols="12" md="3">  Couleur
                <v-color-picker
               
                label="Couleur"
                v-model="internalItem.color"
                elevation="15"
              
       ></v-color-picker></v-col>
              
      </v-row></v-card-text>
       <v-card-actions>
          <v-btn text  @click="save">
           Sauver
          </v-btn>
          <v-btn text  @click="open">
           Supprimer
          </v-btn>
          <v-btn text  @click="close">
           Annuler
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-col></v-row>
    <v-dialog
      v-model="dialog"
      persistent
      max-width="400"
    >
      <v-card>
        <v-card-title class="text-h5 " style="word-break: break-word;">
          Etes vous sure de vouloir supprimer cet utilisteur ?
        </v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
          
            text
            @click="dialog = false"
          >
            Annuler
          </v-btn>
          <v-btn
           
            text
            @click="supprime"
          >
            Supprimer!
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    </v-container></template>
    <script>
import serviceCollabs from '../service/serviceCollabs.js'
import { v4 as uuidv4 } from "uuid";
import router from "@/router";
  export default {
    mounted() {
      if(this.$route.params.id){
      serviceCollabs.getById(this.$route.params.id).then(response =>
      {
        if(!response.data.color)
        {
          response.data.color="#121212"
        }
        this.internalItem=Object.assign({}, response.data);
   
      })}
      else{
        this.internalItem={
          id: uuidv4(),
          color : "#121212",
          name : "",
          prenom:"",
          email:"",
          capacite:"7"}
        }
      
    },
    data: () => ({
      dialog: false,
      internalItem: {}
    }), 
      watch: {
     dialog(val) {
        if(!val)
        this.$emit("close")
    }},
    methods: {
      supprime() {
        if (this.id) {
          this.dialog=false;
        
              this.$store.commit('DELETE_COLLAB',this.internalItem.id)
              router.go(-1)
            }
    },
      close() {
   router.go(-1)
    }, save() {
   
     if (this.id) {
      
              this.$store.commit('UPDATE_COLLAB',this.internalItem)
            }
            else{
        this.$store.commit('ADD_COLLAB',this.internalItem)
            }
   router.go(-1)

    },
    open() {
      this.dialog=true;
    }
  },
  props: {
     id: {
      type:String,
      default: "",
    },
    editedItem: {
      type: Object,
      default: () => ({}),
    },
  },

  }
</script>