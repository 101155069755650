<template> <v-container>
  <v-row dense   class="pb-16">
    <v-col cols="12">
      <v-card
     
        
      >
        <v-card-title class="text-h5" >
          Edition Collaborateur Déplacement
        </v-card-title>
        <v-card-subtitle class="text-h5" >
        <v-chip :color="internalItem.color" dark> {{internalItem.prenom}} {{internalItem.name}}</v-chip>   
        </v-card-subtitle>

        <v-card-text>
          <Calendar :display-week-number="true"
          :enable-range-selection="true"
            :data-source="dataSource"
            :enable-context-menu="true"
            :context-menu-items="contextMenuItems"
            @select-range="selectRange"    
         language="fr"></Calendar>
        </v-card-text>
       <v-card-actions>
          <v-btn text  @click="save">
           Sauver
          </v-btn>
          <v-btn text  @click="close">
           Annuler
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-col></v-row>

  <oneTravel  v-on:save="saveTravel" v-on:delete="deleteTravel"  ref="editTravel"/>
    </v-container></template>
    <script>

import serviceCollabs from '../service/serviceCollabs.js'
import oneTravel from "../components/oneTravel.vue";
import { v4 as uuidv4 } from "uuid";
import router from "@/router";
import Calendar from 'v-year-calendar';
import 'v-year-calendar/locales/v-year-calendar.fr';
  export default {
    components: {
    Calendar,oneTravel
  }, 
    mounted() {
      if(this.$route.params.id){
      serviceCollabs.getById(this.$route.params.id).then(response =>
      {
        if(!response.data.color)
        {
          response.data.color="#121212"
        }
        this.internalItem=Object.assign({}, response.data);
        serviceCollabs.getTravelById(this.$route.params.id).then(responseOfo =>{
          this.dataSource = responseOfo.data.map((ev)=>
           {return {id: ev.id,
          name : ev.name,
          startDate : new Date(ev.debut), 
          endDate : new Date(ev.fin),
          capa : ev.charge,
          type: ev.type
}}
          );
       
            })
   
      })}
      else{
        this.internalItem={
          id: uuidv4(),
          color : "#121212",
          name : "",
          prenom:"",
          email:"",
          capacite:"7"}
        }
      
    },
    data: () => ({
      show: false,
      currentId: null,
      currentStartDate: null,
      currentEndDate: null,
      currentName: null,
      currentLocation: null,
      dialog: false,
      dataSource: [],
      internalItem: {},
      contextMenuItems: [
        {
          text: "Update",
          click: evt => {
            this.currentId = evt.id;
            this.currentStartDate = evt.startDate.toISOString().substring(0, 10);
            this.currentEndDate = evt.endDate.toISOString().substring(0, 10);
            this.currentName = evt.name;
            this.currentLocation = evt.location;

            this.show = true;
          }
        },
        {
          text: "Delete",
          click: evt => {
            this.dataSource = this.dataSource.filter(item => item.id != evt.id);
          }
        }
      ]
    }), 
      watch: {
     dialog(val) {
        if(!val)
        this.$emit("close")
    }},
    methods: {
      selectRange: function(e) {
      this.currentId = null;
      this.currentName = null;

{
        var tmpStartDate =new Date( e.startDate);
            var tmpEndDate =new Date(  e.endDate);
        tmpStartDate.setDate(e.startDate.getDate() + 1)
        tmpEndDate.setDate(e.endDate.getDate() + 1)
       
      this.currentStartDate =tmpStartDate.toISOString().substring(0, 10);
      this.currentEndDate = tmpEndDate.toISOString().substring(0, 10);
      }
      

      if( this.dataSource.filter((event)=>event.startDate.toISOString().substring(0, 10) ==  this.currentStartDate && event.endDate.toISOString().substring(0, 10) ==  this.currentEndDate).length >0 ){
     
        var crtevt= this.dataSource.filter((event)=>event.startDate.toISOString().substring(0, 10) ==  this.currentStartDate && event.endDate.toISOString().substring(0, 10) ==  this.currentEndDate)[0]
        this.$refs.editTravel.open(   {

id : crtevt.id,
start : this.currentStartDate,
end :   this.currentEndDate,
name :   crtevt.name,
capa:  crtevt.capa,
type:crtevt.type
});
      }
      else
      {
      this.$refs.editTravel.open( {

        id : this.currentId,
        start : this.currentStartDate,
        end :   this.currentEndDate,
        name :  this.currentName,
       capa:  this.internalItem.capacite
  
      });}
    },
      supprime() {
       
    },
      close() {
   router.go(-1)
    },
    deleteTravel(item){
      serviceCollabs.deleteTravel(this.$route.params.id,item.id);
      serviceCollabs.getTravelById(this.$route.params.id).then(responseOfo =>{
          this.dataSource = responseOfo.data.map((ev)=>
           {return {id: ev.id,
          name : ev.name,
          startDate : new Date(ev.debut), 
          endDate : new Date(ev.fin),
          capa : ev.charge,
          type: ev.type
}}
          );
      
            })
   
    },

    saveTravel(item){
      serviceCollabs.postTravel(this.$route.params.id,
      { id: item.id,
        debut: item.start,
        type: item.type,
        fin: item.end,
       collabId: this.$route.params.id,
          name: item.name,
        charge: item.capa,
    
      })
      this.dataSource.push(
{
      id: item.id,
          name: item.name,
          color: item.color,
          startDate: new Date(item.start), 
          endDate: new Date( item.end),
          capa: item.capa,
          type: item.type
})

    },
    save() {
   
  
   router.go(-1)

    },
    open() {
      this.dialog=true;
    }
  },
  props: {
     id: {
      type:String,
      default: "",
    },
    editedItem: {
      type: Object,
      default: () => ({}),
    },
  },

  }
</script>