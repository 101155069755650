<template>

    <myPlanningCollab/>

</template>

<script>

import myPlanningCollab from '../components/myPlanningCollab'
export default {
  mounted() {
     this.$store.commit( 'UPDATE_SUBTITLE', "Planning collab.")
        
  },
  name: "planning-view",
  components: {
    myPlanningCollab
  },
  methods: {

  }
};
</script>