<template>
  <v-dialog v-model="dialog" fullscreen hide-overlay transition="dialog-bottom-transition" scrollable>
    <v-card>
      <v-card-title class="pa-0" dark>
        <v-app-bar style="height: 50px;" dark color="primary">
          <v-btn icon dark @click="dialog = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>Absence : {{crtTask.name}}</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-toolbar-items>
            <v-btn dark text @click="save">
              Enregistrer
            </v-btn>
            <v-btn v-if="coulddel" dark text @click="supp">
              Supprimer
            </v-btn>
          </v-toolbar-items>
        </v-app-bar>
      </v-card-title>

      <v-card-text>
<br/>
        <v-row>        
          
          <v-col cols="12" sm="6">
         <v-select :readonly="!user.isAdmin " ref="selectcollab" v-model="crtTask.collabId" :items="collabs" label="Collaborateur" dense 
             :item-value="'id'"  :item-text="'nom'"  :clearable="user.isAdmin"  >
             <template slot="selection" slot-scope="data">
              <!-- HTML that describe how select should render selected items -->
              {{ data.item.name }} {{ data.item.prenom }}
            </template>

              <template v-slot:item="{item, attrs, on }">
                <v-list-item v-on="on" v-bind="attrs" #default="{ active }">
                  <v-list-item-action>
                  <v-checkbox :input-value="active"></v-checkbox>
                  </v-list-item-action>
                  <v-list-item-content>
                    <v-list-item-title>
                      <v-row no-gutters align="center">
                      <v-chip text-color="white" :color="item.color" small
                      >{{ item.name }} {{ item.prenom }}</v-chip> 
                    
                      </v-row>
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </template>
            
            </v-select>
          </v-col>
          <v-col cols="12" sm="6">

            <v-text-field  
            v-model="crtTask.name" label="Titre absence"></v-text-field>
          </v-col>
          <v-col cols="12" sm="6">
            <v-menu
                  v-model="menu2"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
            
                    v-model="crtTask.debut"
                      label="Début"
                      prepend-icon="mdi-calendar"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                  local="FR-fr"
                  :first-day-of-week="1"
                  show-current
        
                  v-model="crtTask.debut"
                    @input="menu2 = false"
                  ></v-date-picker> </v-menu
              >
            </v-col>
<v-col cols="12" sm="6">
  <v-menu
                  v-model="menu3"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
            
                    v-model="crtTask.fin"
                      label="Fin"
                      prepend-icon="mdi-calendar"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                  local="FR-fr"
                  :first-day-of-week="1"
                  show-current
        
                  v-model="crtTask.fin"
                    @input="menu3 = false"
                  ></v-date-picker> </v-menu
              >

</v-col>

<v-col cols="12" sm="6">

<v-text-field  
v-model="crtTask.charge" label="Temps off"></v-text-field>
</v-col>
<v-col cols="12" sm="6">


<v-select  v-model="crtTask.type" :items="typeabs" label="Type Absence" dense 
              :item-text="'name'" :item-value="'code'" ></v-select>

</v-col>     <v-col cols="12" sm="3">

<v-select :readonly="!user.isAdmin && !oneOfMine" v-model="crtTask.recurrence" :items="recurrence" label="recurrence" dense  :item-text="'name'"
  :item-value="'code'" :clearable="user.isAdmin || oneOfMine"></v-select>

</v-col>
<v-col cols="12" sm="6">
<v-menu v-if="crtTask.recurrence && crtTask.recurrence!=0"
      v-model="menu1"
      :close-on-content-click="false"
      :nudge-right="40"
      transition="scale-transition"
      offset-y
      min-width="auto"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-text-field
        v-model="crtTask.finrecurrence"
          label="Fin de recurrence"
          prepend-icon="mdi-calendar"
          readonly
          v-bind="attrs"
          v-on="on"
        ></v-text-field>
      </template>
      <v-date-picker
     v-if="user.isAdmin ||  oneOfMine"
      v-model="crtTask.finrecurrence"
      locale="FR-fr"
      :first-day-of-week="1"
        @input="menu = false"
      ></v-date-picker> </v-menu
  >
</v-col> 
        </v-row>
      </v-card-text>
      <div style="flex: 1 1 auto;"></div>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapState } from "vuex";
import { v4 as uuidv4 } from 'uuid';
export default {
  mounted() {


  },
  props: {
  }, computed: {
    ...mapState({
      alerts: "alerts",
      clients: "clients",
      collabs: "collabs",
      tasks: "tasks",
      user: "user",
      typeabs:"typeabs",
      recurrence: "recurrence",
      status: "status"

    }),
    
  },
  watch: {
    dialog(val) {

      if (!val)
      this.$nextTick(() =>{   this.$emit("close")})
    }
  },
  methods: {
    supp() {
   
      this.$emit("delete",this.crtTask);
     
      this.dialog = false;
    },
    save() {

      this.$emit("save",this.crtTask);
      this.dialog = false;
    },
    open(tache) {
      this.dialog = true;
      this.crtTask = tache
      if (this.crtTask.id) {
        this.coulddel =true;
      
      }else{  
        this.crtTask.id= uuidv4();
        this.crtTask.finrecurrence  = "2024-12-31"
      }
      
    }
  },
  data() {
    return {
      coulddel:false,
      dialog: false,
      crtTask: {},
      menu1: false,
      menu2: false,
    }
  },
}
</script>