<template>

    <myPlanningClient/>

</template>

<script>

import myPlanningClient from '../components/myPlanningClient'
export default {
  mounted() {
     this.$store.commit( 'UPDATE_SUBTITLE', "Planning Client")
        
  },
  name: "planning-view",
  components: {
    myPlanningClient
  },
  methods: {

  }
};
</script>