<template>  <v-container> <v-dialog
      v-model="dialog"
      persistent
      max-width="600px"
    >

      <v-card>
        <v-card-title>
          <span class="text-h5">Edition Tache</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
                   <v-col
                cols="12"
                sm="6"
              >
                <v-autocomplete
                  :items="['Client 01', 'Client 02', 'Client 03', 'Client 04', 'Client 05', 'Client 06', 'Client 07', 'Client 08', 'Client 09']"
                  label="Client"
                ></v-autocomplete>
              </v-col>
                             <v-col
                cols="12"
                sm="6"
              >
                <v-autocomplete
                  :items="['Collab 01', 'Collab 02', 'Collab 03', 'Collab 04', 'Collab 05', 'Collab 06']"
                  label="Collaborateur"           
                ></v-autocomplete>
              </v-col>
               <v-col
                cols="8"
              
              >        <v-select
          :items="rectype"
          label="Recurrence"
          dense
        ></v-select></v-col>
        <v-col
                cols="4"
              
              >
                <v-text-field
                  label="Fin"
                  required
                ></v-text-field>
              </v-col>
              <v-col
                cols="10"
              
              >
                <v-text-field
                  label="Tache"
                  required
                ></v-text-field>
              </v-col>
                <v-col
                cols="2"

              >
                <v-text-field
                  label="Statut"
                  required
                ></v-text-field>
              </v-col>
                           <v-col
                cols="4"
              >
                <v-text-field
                  label="Temps prevu (heures)"
                  required
                ></v-text-field>
              </v-col>
                                         <v-col
                cols="4"
                sm="4"
                md="4"
              >
                <v-text-field
                  label="Temps passé (heures)"
                  required
                ></v-text-field>
              </v-col>
                                                      <v-col
                cols="4"
                sm="4"
                md="4"
              >
                <v-text-field
                  label="Reste (heures)"
                  required
                ></v-text-field>
              </v-col>
                <v-col
                cols="12"
              
              >
                <v-textarea
                  label="Detail"
                  required
                ></v-textarea>
              </v-col>
            </v-row>
          </v-container>
          <small>*champs obligatoires</small>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="blue darken-1"
            text
            @click="dialog = false"
          >
            Close
          </v-btn>
          <v-btn
            color="blue darken-1"
            text
            @click="dialog = false"
          >
            Save
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog></v-container></template>
    <script>
  export default {
    data: () => ({
      dialog: false,
      rectype:["Toutes les semaines",
"Dernier jour du mois",
"Premier jours  du mois",
"Tout les mois",
"Tout les ans"]
    }), 
      watch: {
     dialog(val) {
        if(!val)
        this.$emit("close")
    }},
    methods: {

    open() {
      this.dialog=true;
    }
  },
  }
</script>