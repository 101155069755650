var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"fullscreen":"","hide-overlay":"","transition":"dialog-bottom-transition","scrollable":""},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-card-title',{staticClass:"pa-0",attrs:{"dark":""}},[_c('v-app-bar',{staticStyle:{"height":"50px"},attrs:{"dark":"","color":"primary"}},[_c('v-btn',{attrs:{"icon":"","dark":""},on:{"click":function($event){_vm.dialog = false}}},[_c('v-icon',[_vm._v("mdi-close")])],1),_c('v-toolbar-title',[_vm._v("Absence : "+_vm._s(_vm.crtTask.name))]),_c('v-spacer'),_c('v-toolbar-items',[_c('v-btn',{attrs:{"dark":"","text":""},on:{"click":_vm.save}},[_vm._v(" Enregistrer ")]),(_vm.coulddel)?_c('v-btn',{attrs:{"dark":"","text":""},on:{"click":_vm.supp}},[_vm._v(" Supprimer ")]):_vm._e()],1)],1)],1),_c('v-card-text',[_c('br'),_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('v-select',{ref:"selectcollab",attrs:{"readonly":!_vm.user.isAdmin,"items":_vm.collabs,"label":"Collaborateur","dense":"","item-value":'id',"item-text":'nom',"clearable":_vm.user.isAdmin},scopedSlots:_vm._u([{key:"selection",fn:function(data){return [_vm._v(" "+_vm._s(data.item.name)+" "+_vm._s(data.item.prenom)+" ")]}},{key:"item",fn:function(ref){
var item = ref.item;
var attrs = ref.attrs;
var on = ref.on;
return [_c('v-list-item',_vm._g(_vm._b({scopedSlots:_vm._u([{key:"default",fn:function(ref){
var active = ref.active;
return [_c('v-list-item-action',[_c('v-checkbox',{attrs:{"input-value":active}})],1),_c('v-list-item-content',[_c('v-list-item-title',[_c('v-row',{attrs:{"no-gutters":"","align":"center"}},[_c('v-chip',{attrs:{"text-color":"white","color":item.color,"small":""}},[_vm._v(_vm._s(item.name)+" "+_vm._s(item.prenom))])],1)],1)],1)]}}],null,true)},'v-list-item',attrs,false),on))]}}]),model:{value:(_vm.crtTask.collabId),callback:function ($$v) {_vm.$set(_vm.crtTask, "collabId", $$v)},expression:"crtTask.collabId"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('v-text-field',{attrs:{"label":"Titre absence"},model:{value:(_vm.crtTask.name),callback:function ($$v) {_vm.$set(_vm.crtTask, "name", $$v)},expression:"crtTask.name"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('v-menu',{attrs:{"close-on-content-click":false,"nudge-right":40,"transition":"scale-transition","offset-y":"","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":"Début","prepend-icon":"mdi-calendar","readonly":""},model:{value:(_vm.crtTask.debut),callback:function ($$v) {_vm.$set(_vm.crtTask, "debut", $$v)},expression:"crtTask.debut"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.menu2),callback:function ($$v) {_vm.menu2=$$v},expression:"menu2"}},[_c('v-date-picker',{attrs:{"local":"FR-fr","first-day-of-week":1,"show-current":""},on:{"input":function($event){_vm.menu2 = false}},model:{value:(_vm.crtTask.debut),callback:function ($$v) {_vm.$set(_vm.crtTask, "debut", $$v)},expression:"crtTask.debut"}})],1)],1),_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('v-menu',{attrs:{"close-on-content-click":false,"nudge-right":40,"transition":"scale-transition","offset-y":"","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":"Fin","prepend-icon":"mdi-calendar","readonly":""},model:{value:(_vm.crtTask.fin),callback:function ($$v) {_vm.$set(_vm.crtTask, "fin", $$v)},expression:"crtTask.fin"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.menu3),callback:function ($$v) {_vm.menu3=$$v},expression:"menu3"}},[_c('v-date-picker',{attrs:{"local":"FR-fr","first-day-of-week":1,"show-current":""},on:{"input":function($event){_vm.menu3 = false}},model:{value:(_vm.crtTask.fin),callback:function ($$v) {_vm.$set(_vm.crtTask, "fin", $$v)},expression:"crtTask.fin"}})],1)],1),_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('v-text-field',{attrs:{"label":"Temps off"},model:{value:(_vm.crtTask.charge),callback:function ($$v) {_vm.$set(_vm.crtTask, "charge", $$v)},expression:"crtTask.charge"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('v-select',{attrs:{"items":_vm.typeabs,"label":"Type Absence","dense":"","item-text":'name',"item-value":'code'},model:{value:(_vm.crtTask.type),callback:function ($$v) {_vm.$set(_vm.crtTask, "type", $$v)},expression:"crtTask.type"}})],1),_vm._v(" "),_c('v-col',{attrs:{"cols":"12","sm":"3"}},[_c('v-select',{attrs:{"readonly":!_vm.user.isAdmin && !_vm.oneOfMine,"items":_vm.recurrence,"label":"recurrence","dense":"","item-text":'name',"item-value":'code',"clearable":_vm.user.isAdmin || _vm.oneOfMine},model:{value:(_vm.crtTask.recurrence),callback:function ($$v) {_vm.$set(_vm.crtTask, "recurrence", $$v)},expression:"crtTask.recurrence"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"6"}},[(_vm.crtTask.recurrence && _vm.crtTask.recurrence!=0)?_c('v-menu',{attrs:{"close-on-content-click":false,"nudge-right":40,"transition":"scale-transition","offset-y":"","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":"Fin de recurrence","prepend-icon":"mdi-calendar","readonly":""},model:{value:(_vm.crtTask.finrecurrence),callback:function ($$v) {_vm.$set(_vm.crtTask, "finrecurrence", $$v)},expression:"crtTask.finrecurrence"}},'v-text-field',attrs,false),on))]}}],null,false,4087704837),model:{value:(_vm.menu1),callback:function ($$v) {_vm.menu1=$$v},expression:"menu1"}},[(_vm.user.isAdmin ||  _vm.oneOfMine)?_c('v-date-picker',{attrs:{"locale":"FR-fr","first-day-of-week":1},on:{"input":function($event){_vm.menu = false}},model:{value:(_vm.crtTask.finrecurrence),callback:function ($$v) {_vm.$set(_vm.crtTask, "finrecurrence", $$v)},expression:"crtTask.finrecurrence"}}):_vm._e()],1):_vm._e()],1)],1)],1),_c('div',{staticStyle:{"flex":"1 1 auto"}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }