
<template>  <v-sheet
    >
  <v-data-table
  
    :items="clients"
    item-key="name"
    disable-pagination
        hide-default-footer
    class="elevation-1"
  >   
 
      <template v-slot:header>
        <thead>
          <tr>
            <th >
   
              Client
            </th>
                        <th >
              Lundi
            </th>
                        <th >
              Mardi
            </th>
             <th >
              Mercredi
            </th>
             <th >
              Jeudi
            </th>
             <th >
              Vendredi
            </th>
             <th >
              Samedi
            </th>
             <th >
              Dimanche
            </th>
          </tr>
        </thead>
      </template>

    <template v-slot:item="{ item }">
             <tr    :key="item.name" >
              <td>  <h1>
                      <v-chip style="width: 100%;" :color="item.color" dark> {{ item.name }}</v-chip>
                    </h1>
             </td>
           <td v-for=" crtday in weeklytask(item.id,filter.semaine)" :key="crtday.numero" style="vertical-align: top;"> 
                  {{crtday.duedate}}   
                  <v-btn   v-if="user.isAdmin"   @click="addone(item.id,crtday.duedate) ">  <v-icon >mdi-plus</v-icon></v-btn>
                <v-card class="mx-auto rounded-lg"
                v-for=" crttache in crtday.taches" :key="crttache.id"
                v-on:click="GotoTask(crttache)"
                >            
                <v-card-subtitle    class="pa-0" dark>
                             <v-app-bar
            style="height: 50px;"
       :color="collabs.filter(collab => collab.id == crttache.collab)[0].color"
         text-color="white"
          >
         

            <v-toolbar-title class="text-subtitle-2 white--text pl-0">
            {{crttache.collabLabel}}
            </v-toolbar-title>

            <v-spacer></v-spacer>
 <v-toolbar-title class="text-subtitle-2 white--text pl-0">
            {{crttache.realise}}h / {{crttache.prevu}}h
            </v-toolbar-title>
          </v-app-bar>  
                    
                    </v-card-subtitle>   
                  <v-card-text>
                 {{crttache.name}}
                  </v-card-text>
                </v-card>
              </td>
        
             </tr>
  </template>
        </v-data-table>
        <editEvent ref="editevent" v-on:close="closeedit"/>
              <oneTask   ref="editTask"/>
        </v-sheet> 
</template>


<script>

import { mapState } from "vuex";
import editEvent from '../components/editEvent'
import oneTask from "../components/oneTask.vue";
import serviceTache from '../service/serviceTache.js'
import serviceCalendrier from '../service/serviceCalendrier.js'
  export default {
    props: {

    filter: {
      type: Object,
      default: () => ({
        annee: new Date().getFullYear().toString(),
        mois: "",
        semaine: ""
      }),
    },
  },  watch: {
    'lastUpdate'() {
      this.refresh () ;
    },
    'filter.semaine'() {
        this.refreshSemaine();
    }, 'filter.annee'() {
      this.refreshSemaine();
      }},
      mounted() {
        this.refreshSemaine();
      
    },
  computed: {
    ...mapState({
      tasks: "tasks",
      collabs: "collabs",
      clients: "clients",
      user:"user",
    }),
        weekarray() {
      let res = []
      if (this.filter && this.filter.annee && this.filter.mois&& this.filter.semaine) {

        var crtWeek = [];
        res.push(crtWeek);

        var premier = new Date(this.filter.annee + '-' + this.filter.mois + '-1').getDay();
        if (premier == 0) premier = 7;
        for (let index = 1; index < premier; index++) {
          crtWeek.push({date: this.filter.annee + '-' + this.filter.mois + '--' + index});
        }
        for (let index = 1; index <= 31; index++) {

          if (this.dateIsValid(new Date(this.filter.annee + '-' + this.filter.mois + '-' + index))) {

            crtWeek.push({ date: this.filter.annee + '-' + this.filter.mois + '-' + index, 
            taches: this.daylytask(new Date(this.filter.annee + '-' + this.filter.mois + '-' + index), this.filteredTask) , numero:index
             });
            if ((new Date(this.filter.annee + '-' + this.filter.mois + '-' + index)).getDay() == 0) {
            
             crtWeek = []
              res.push(crtWeek);
            }
          }
        }
      }
    
      return res;
    },
    filteredTask() {
      var res = this.tasks;

      if (this.filter && this.filter.annee)
        res = res.filter((tache) => tache.annee == this.filter.annee);
      if (this.filter && this.filter.mois)
        res = res.filter((tache) => tache.mois == this.filter.mois);
     /* if (this.filter && this.filter.semaine)
        res = res.filter((tache) => tache.semaine == this.filter.semaine);
      */
      return res;
    },},
    components: {oneTask,editEvent},
     methods: {
      addone(who, when){ this.$refs.editTask.open( {client:who, duedate:when});},
      GotoTask(atask){
    serviceTache.getById(atask.id).then(response=>{   this.$refs.editTask.open( response.data );})
   
    },
      refreshSemaine()
      {

        serviceCalendrier.week(this.filter.annee, this.filter.semaine).then(response =>
        {
          this.debut=response.data.debut
          this.fin=response.data.fin
        })
      },
          formatdate(unedate){
    var datestring = unedate.getFullYear()+ "-" + ("0"+(unedate.getMonth()+1)).slice(-2) + "-" + ("0" + unedate.getDate()).slice(-2)  ;
    return datestring
    },

   daylytaskByClient(clientid, taches){

    var res =[]
      if(taches)
      res = taches.filter(tache => tache.client ==clientid );
     
      return res;
     },

    daylytask(thedate, taches){
      var res=[]
    
      res = taches.filter(tache => tache.duedate == this.formatdate(thedate))
  
      return res;
    },
      weeklytask(clientid,semaine)
      {
        var res =[];
   
        if (this.filter && this.filter.annee &&  semaine) {
   
        const datedebut = new Date( this.debut);
        for (let index = 0; index < 7; index++) {
          var crtdate = new Date( datedebut);
           crtdate.setDate(crtdate.getDate() + index)
          res.push({
          numero:crtdate.getDay(),
          duedate:this.formatdate(crtdate),
          client:clientid, 
          taches:  this.daylytaskByClient(clientid,this. daylytask(crtdate,this.filteredTask))
          })
        }
        
        }
        return res;
      },
       getMonday(d) {
        d = new Date(d);
        var day = d.getDay(),
            diff = d.getDate() - day + (day == 0 ? -6:1); // adjust when day is sunday
        return new Date(d.setDate(diff));
      },
       closeedit(){
          this.editing=false;
       },
    editEvent(item, event) {
     this.editing=true;
        if (event) {
      event.preventDefault()
    }
    
this.$refs.editevent.open();
    },
    switchCol(item) {
      if(!this.editing)
      item.colapsed = ! item.colapsed
    }
  },
    data () {
      return {
        debut:null,
        fin:null,

      }
    },
  }
</script>
