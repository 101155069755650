<template>
  <v-row class="Flex ">
      
        <v-col cols="10" v-for=" crttache in myTasks" :key="crttache.id">
  <v-card
 
    class="mx-auto"
    max-width="344"
     elevation="3"
  outlined
  
    
  >
   <v-card-title
   class="text-h5 dark white--text"
   :class="{ red: IsAlert(crttache),
  orange:IsWarning(crttache),
  green:IsDone(crttache),
  blue:IsTodo(crttache), }"
        >
        <v-icon class="white--text" v-if=" IsAlert(crttache)" large>mdi-alert-outline</v-icon>  {{crttache.name}}
            </v-card-title>

    <v-list-item three-line>
      <v-list-item-content>
       <div class="text-overline mb-4" >
          {{crttache.clientLabel}}
        </div>
        <v-list-item-title class="text-h5 mb-1">
          {{crttache.duedate}}
        </v-list-item-title>
        <v-list-item-subtitle>{{crttache.text}}</v-list-item-subtitle>
      </v-list-item-content>


    </v-list-item>

    <v-card-actions>
  

            <v-btn
         elevation="3"
  outlined
  v-on:click="GotoTask(crttache)"
        rounded
        text
      >
        Màj
      </v-btn>
    </v-card-actions>
  </v-card>
  </v-col>
      


  <oneTask   ref="editTask"/>
  </v-row>
 
</template>

<script>

import { mapState } from "vuex";
import oneTask from "../components/oneTask.vue";
import serviceTache from '../service/serviceTache.js'
export default {
  components: {oneTask},
  methods: {
    IsAlert(aTask){
    
      var todayDate = new Date(); //Today Date    
           var dateOne =  Date.parse(aTask.duedate);    
      return (aTask.statut != "07"  && todayDate > dateOne)
    },

    IsWarning(aTask){
      var todayDate = new Date(); //Today Date    
      var dateOne =  Date.parse(aTask.duedate);  
      var Difference_In_Time = dateOne.getTime() - todayDate.getTime();
      var Difference_In_Days = Difference_In_Time / (1000 * 3600 * 24);
      return  (aTask.statut != "07"  && Difference_In_Days <= 7)
    },
    IsDone(aTask){
   
      return  (aTask.statut == "07"  )
    },
    IsTodo(aTask){
      var todayDate = new Date(); //Today Date    
           var dateOne =  Date.parse(aTask.duedate);    
           var Difference_In_Time = dateOne.getTime() - todayDate.getTime();
      var Difference_In_Days = Difference_In_Time / (1000 * 3600 * 24);
    
      if (aTask.statut != "07"  && todayDate > dateOne) return false
      if (aTask.statut != "07"  &&  Difference_In_Days <=7) return false
      if (aTask.statut == "07"  ) return false
     return true
    },
    GetColor(aTask){
      var todayDate = new Date(); //Today Date    
           var dateOne =  Date.parse(aTask.duedate);    
      if (aTask.statut != "07"  && todayDate > dateOne) return 'red'
      if (aTask.statut != "07"  && todayDate == dateOne) return "orange"
      if (aTask.statut == "07"  ) return "green"
     return "blue"
    },
    GotoTask(atask){
    serviceTache.getById(atask.id).then(response=>{   this.$refs.editTask.open( response.data );})
   
    },
  },
  computed: {
         ...mapState({
      tasks: "tasks",
user:"user",
    }),
    myTasks() {
     
      return this.tasks.filter(t=> t.collab == this.user.id && (t.statut != "07" ||  new Date() <= Date.parse(t.duedate) ))
    }
   
    },
  
  data() {
    return {
    
    };
  },
};
</script>
